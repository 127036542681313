/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const faqs = [
    {
        question: 'How do internships work?',
        answer: 'An intern is normally a university/college student looking to gain industry experience during their breaks from school. Our network consists mostly of CS and UI/UX interns who are looking to work at a tech startup. Internships are a great way for startups to lay soil for talent you can onboard full-time later on, or expedite development speed for a few months.',
    },
    {
        question: 'How long is an internship?',
        answer: 'Internships normally last 3-4 months, although it can be longer depending on the student and their school.',
    },
    {
        question: 'How much are interns paid?',
        answer: "It's up to you to set the rate. However, as our network consists of talented students across top universities, most of whom have already worked at startups/tech companies, $25-35/hr is the range to be matched with good candidates, and exceptional candidates may expect $50+/hr. ",
    },
    {
        question: 'Will the internship be remote or in-person?',
        answer: 'That is an arrangement you can make with the intern. In our post-pandemic world, most people find remote more convenient, however if you prefer to have an in-person internship you can include it in the role listing.',
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function FAQ() {
    return (
        <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt className="text-lg">
                                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                <span className="font-medium text-gray-900">{faq.question}</span>
                                                <span className="ml-6 h-7 flex items-center">
                                                    <ChevronDownIcon
                                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base text-gray-500">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
