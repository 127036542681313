export const roleOptions = [
    'AI Engineer',
    'Fullstack Web Engineer',
    'Product Manager',
    'Frontend Engineer',
    'Backend Engineer',
    'Data Scientist',
    'System Security Engineer',
    'Machine Learning Engineer',
    'Mobile Developer',
    'Robotics Engineer',
    'Cloud Engineer',
    'Embedded Systems Engineer',
    'Electrical Engineer',
    'Hardware Engineer',
    'DevOps Engineer',
    'Electrical Engineer',
    'Smart Contract Developer',
    'UI/UX Designer',
    'Product Designer',
];
