import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { skillOptions } from '../variables/SkillOptions';

function CustomSkillSelect() {
    const [selectedSkills, setSelectedSkills] = useState([]);

    // Format the skill options for react-select
    const formattedSkillOptions = skillOptions.map(skill => ({ value: skill, label: skill }));

    // Handle selection changes
    const handleChange = (selectedOption) => {
        setSelectedSkills(selectedOption);
    };

    // Handle creation of a new skill
    const handleCreate = (inputValue) => {
        const newOption = { value: inputValue, label: inputValue };
        setSelectedSkills([...selectedSkills, newOption]);
        // Here you can also update the skillOptions array or send the new skill to your backend
    };

    return (
        <CreatableSelect
            isMulti
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={formattedSkillOptions}
            value={selectedSkills}
            placeholder="Select or type skills..."
        />
    );
}

export default CustomSkillSelect;