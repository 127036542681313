import { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { db, auth, storage } from '../../firebase/config';
import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';

import Loader from '../Loader';
import { ReactComponent as Screening } from '../assets/Screening.svg';
import { Socials } from '../Socials';

const TalentJobPropositions = () => {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, 'talent', user.uid)).then((docSnap) => {
                    setData(docSnap.data());
                    setLoading(false);
                });
            }
        });
    }, []);

    if (loading) return <Loader />;
    else
        return (
            <div className="relative mx-12 my-5 text-center mt-12 md:mt-32">
                <Screening />
                <div className="mt-6" />
                <span className="text-lg font-medium sm:text-2xl">We're matching you with the perfect internship!</span>
                <br />
                <span className="text-md sm:text-xl">You'll be notified when we have updates.</span>
                <br />
                <br />
                <p className="text-sm sm:text-md text-gray-500">
                    Here's the access to our Sonder Community Discord
                    <br className="hidden md:block" /> where <b>you'll hear first</b> about ✨<b>special</b>✨ updates:
                </p>
                <Socials />
            </div>
        );
};

export default TalentJobPropositions;
