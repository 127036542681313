export const skillOptions = [
    'ReactJS',
    'NodeJS',
    'Python',
    'TailwindCSS',
    'MongoDB',
    'Firebase',
    'AWS',
    'Google Cloud',
    'Azure',
    'MySQL',
    'PostgreSQL',
    'Javascript',
    'Web App UI/UX',
    'Mobile App UI/UX',
    'AngularJS',
    'Typescript',
    'Java',
    'Flask',
    'ExpressJS',
    'Flutter/Dart',
    'React Native',
    'iOS/Swift',
    'Android',
    'DevOps',
    'Kotlin',
    'Solidity',
    'Rust',
    'Django',
    'Pandas',
    'Spring Boot',
    'Machine Learning',
    'Keras',
    'Tensorflow',
    'Data Engineering',
    'Microsoft Office',
];
