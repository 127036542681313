import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../firebase/config';
import { stripeLink } from '../variables/Stripe';
import { doc, getDoc } from 'firebase/firestore';
import Loader from '../Loader';
import { CheckCircleIcon, LocationMarkerIcon, AcademicCapIcon, ClockIcon, CalendarIcon, PlusIcon, MinusIcon } from '@heroicons/react/solid';
import { getDataAsString } from '../../utils/getDateAsString';
import { CandidateCard } from './CandidateCard';
import DirectoryGrid from '../landing/company/DirectoryGrid';
import { Transition } from '@headlessui/react';

const includedFeatures = [
    'Full platform access',
    'New candidates until you hire',
    'View full profiles of interested candidates',
    'Support & resources on intern hiring',
];

const posts = [
    {
        imageUrl: 'https://i.ibb.co/Ns09pMG/Group-3.png',
    },
    {
        imageUrl: 'https://i.ibb.co/mX0BrpM/Group-4.png',
    },
    {
        imageUrl: 'https://i.ibb.co/ZMbF19b/Group-5.png',
    },
    {
        imageUrl: 'https://i.ibb.co/s9W7mVL/Group-2-1.png',
    },
];

export const Candidates = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [paid, setPaid] = useState(false);
    const [job, setJob] = useState(null);
    const [payLinkClicked, setPayLinkClicked] = useState(false);

    useEffect(() => {
        const getPermission = async () => {
            await auth.currentUser.getIdToken(true);
            auth.currentUser
                .getIdTokenResult()
                .then((idTokenResult) => {
                    console.log(idTokenResult.claims);
                    if (!!idTokenResult.claims[`paid_${id}`]) {
                        setPaid(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getPermission();
        getCandidates();

        const seenJobs = localStorage.getItem('seenJobs') || '[]';
        const seenJobsArray = JSON.parse(seenJobs);
        if (!seenJobsArray.includes(id)) {
            seenJobsArray.push(id);
            localStorage.setItem('seenJobs', JSON.stringify(seenJobsArray));
        }
    }, []);

    const getCandidates = async () => {
        const job = (await getDoc(doc(db, 'users', auth.currentUser.uid, 'jobs', id))).data();

        const candidatesIds = job.candidates;
        const candidates = await Promise.all(candidatesIds.map(async (id) => (await getDoc(doc(db, 'talent', id))).data()));

        setCandidates(candidates);
        setJob({
            ...job,
            description: job.job_description,
            title: job.job_title,
            location: job.job_location,
            id: job.id,
        });
        setLoading(false);
    };

    if (payLinkClicked) {
        return (
            <div className="text-center py-24 sm:py-12">
                <h1 className="text-xl font-bold tracking-tight pt-24">Thank you for your payment.</h1>
                <p className="mt-6 text-base text-gray-500">We will contact you shortly once the payment is confirmed.</p>
                <p className="mt-6 text-base text-gray-500">Your candidates will be available soon</p>
                <a
                    href="/home"
                    className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Back To Listings
                </a>
            </div>
        );
    }
    return (
        <>
            {loading ? (
                <Loader />
            ) : paid ? (
                <div className="bg-white">
                    <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Candidates</h2>

                        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
                            {candidates.map((candidate) => (
                                <li key={candidate.full_name}>
                                    <CandidateCard candidate={candidate} />
                                </li>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="bg-gray-50">
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <a href="#pay" className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
                                    Access Your Candidates
                                </a>
                            </div>
                            {job && <JobDisplay job={job} />}
                        </div>

                        <div className="mt-8 pb-16 sm:pb-4 lg:pb-4">
                            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <h2 className="text-3xl font-bold ">Preview some of our picks</h2>
                                <div className="mt-8" />
                                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                    {candidates.slice(0, 2).map((candidate) => (
                                        <li key={candidate.full_name}>
                                            <CandidateCard candidate={candidate} preview />
                                        </li>
                                    ))}
                                    <li>
                                        <a
                                            className="bg-gray-100 overflow-hidden shadow rounded-lg divide-y divide-gray-100 h-full flex flex-col items-center justify-center py-24"
                                            href="#pay"
                                        >
                                            <PlusIcon className="w-24 h-24 text-gray-400" />
                                            <p className="text-gray-400 font-semibold">See More</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-8 bg-gray-50 pb-16 sm:mt-12 sm:pb-20 lg:pb-28" id="pay">
                            <div className="relative">
                                <div className="absolute inset-0 h-1/2 bg-gray-50" />
                                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                                        <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                            <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                                                Get access to our handpicked candidates for this role
                                            </h3>
                                            <div className="flex -space-x-2 relative z-0 overflow-hidden mt-4">
                                                {posts.map((post) => (
                                                    <div
                                                        className="relative z-30 inline-block h-14 w-14 rounded-full ring-2 ring-white overflow-hidden"
                                                        key={post.imageUrl}
                                                    >
                                                        <img className="h-16 w-16" src={post.imageUrl} alt="candidate" />
                                                    </div>
                                                ))}
                                                <div className="relative z-30  h-14 w-14 rounded-full ring-2 ring-white overflow-hidden bg-slate-300 flex justify-center items-center">
                                                    <PlusIcon className="text-white h-10 w-10" />
                                                </div>
                                            </div>
                                            <p className="mt-6 text-base text-gray-500">
                                                Access to the candidates from top university talent in the US & Canada interested in your selected role, and
                                                continuously receive top candidates until you hire.
                                            </p>
                                            <div className="mt-8">
                                                <div className="flex items-center">
                                                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                                                        What's included
                                                    </h4>
                                                    <div className="flex-1 border-t-2 border-gray-200" />
                                                </div>
                                                <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                                    {includedFeatures.map((feature) => (
                                                        <li key={feature} className="flex items-start lg:col-span-1">
                                                            <div className="flex-shrink-0">
                                                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-3 text-sm text-gray-700">{feature}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                            <p className="text-lg leading-6 font-medium text-gray-900">For each role</p>
                                            <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                                <span>$499</span>
                                                <span className="ml-3 text-xl font-medium text-gray-500">USD</span>
                                            </div>
                                            <div className="mt-6">
                                                <div className="rounded-md shadow">
                                                    <a
                                                        href={stripeLink}
                                                        onClick={() => window.location.href = 'https://buy.stripe.com/cN2g1r71WcuAcTudQQ'}
                                                        target="_blank"
                                                        className="cursor-pointer flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                                    >
                                                        Pay Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const JobDisplay = ({ job }) => {
    const [displayJob, setDisplayJob] = useState(false);
    return (
        <div className="block  job-container border-gray-300  shadow-sm border-2 mt-5 rounded-lg job-listing-container">
            <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                    <p className="text-xl font-medium job-offer-title truncate">{job.title}</p>
                </div>

                <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                        <div className="sm:flex sm:mr-4">
                            {job.location && (
                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {job.location}
                                </p>
                            )}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            {job.planned_start_date && (
                                <div className="flex">
                                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <p>Planned start: {getDataAsString(job.planned_start_date)}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="w-full border-t border-gray-300" />

                    <button
                        type="button"
                        className="inline-flex w-full items-center p-4 text-md  font-medium justify-between  text-gray-700 bg-transparent hover:bg-gray-100 focus:outline-none"
                        onClick={() => setDisplayJob((prev) => !prev)}
                    >
                        <span>Details</span>
                        {displayJob ? (
                            <MinusIcon className="-mr-1.5 ml-1 h-8 w-8 text-gray-400" aria-hidden="true" />
                        ) : (
                            <PlusIcon className="-mr-1.5 ml-1 h-8 w-8 text-gray-400 " aria-hidden="true" />
                        )}
                    </button>

                    <Transition
                        show={displayJob}
                        as={Fragment}
                        enter="transition ease-out duration-300"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-300"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="px-4 py-2">
                            <div className="text-gray-500">{job.description}</div>
                            <div className="mt-2">
                                {job.skills && (
                                    <div className="flex flex-wrap -mx-1 gap-2">
                                        {job.skills.map((skill) => (
                                            <div
                                                key={skill}
                                                style={{ width: 'fit-content' }}
                                                className="flex justify-center items-center mb-1 px-3 py-2 border border-indigo-200 rounded-full bg-indigo-200 text-base text-indigo-800"
                                            >
                                                <div className="flex flex-initial items-center max-w-full leading-none text-sm font-medium ">
                                                    <p className="text-sm leading-5 font-medium">{skill}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    );
};
