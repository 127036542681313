import { useState, useEffect, forwardRef } from 'react';
import TextField from './form/TextField';
import Select from './form/Select';
import TextArea from './form/TextArea';
import Loader from './Loader';
import DatePicker from 'react-datepicker';
import ErrorAlert from './modals/ErrorAlert';
import TermsModal from './modals/TermsModal';
import Password from './form/Password';
import { ReactComponent as TeamUp } from './assets/TeamUp.svg';
import { ReactComponent as Intern } from './assets/Intern.svg';
import { ReactComponent as Role } from './assets/Role.svg';
import { ReactComponent as Sketching } from './assets/Sketching.svg';
import { ReactComponent as People } from './assets/People.svg';
import MultiselectComboBox from './form/MultiSelectComboBox';
import { initializeApp } from 'firebase/app';
import { useNavigate, Link } from 'react-router-dom';
import { getFirestore, collection, setDoc, doc, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut, sendPasswordResetEmail, deleteUser, createUserWithEmailAndPassword } from 'firebase/auth';
import { openTermsOfUse } from '../actions';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/onboarding.css';

import { db, auth } from '../firebase/config';

function Registration(props) {
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [startDate, setStartDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [termsValidate, setTermsValidate] = useState(false);

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const navigate = useNavigate();

    const validateStepTwo = () => {
        if (jobTitle && jobLocation) {
            setLoading(true);

            setTimeout(function () {
                setLoading(false);
                setStep(3);
            }, 200);
        }
    };

    const createUser = () => {
        if (!agreedToTerms) {
            setTermsValidate(true);
            return;
        }
        setTermsValidate(false);
        if (password) {
            setLoading(true);
            const userData = {
                name: name,
                email: email,
                company_name: companyName,
            };

            const jobData = {
                job_title: jobTitle,
                job_description: jobDescription,
                planned_start_date: startDate,
                job_location: jobLocation,
            };
            console.log('create');
            createUserWithEmailAndPassword(auth, userData.email, password)
                .then(async (userCredential) => {
                    console.log('create1');
                    // Signed in
                    const user = userCredential.user;

                    await setDoc(doc(db, 'users', user.uid), userData);
                    await addDoc(collection(db, 'users', auth.currentUser.uid, 'jobs'), jobData);
                    console.log('create2');
                    setLoading(false);
                    navigate('/home');
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setAlertMsg(errorMessage);
                    setShowErrorAlert(true);

                    // ..
                });
        }
    };

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <div>
            <label className="block text-sm font-medium text-gray-700">Planned start date</label>
            <label className="block text-xs text-gray-500">Optional</label>
            <div className="mt-1" />
            <input
                type={'text'}
                onClick={onClick}
                ref={ref}
                name={'startDate'}
                id={'startDate'}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={value}
            />
        </div>
    ));

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    const openTerms = (event) => {
        event.preventDefault();
        props.openTermsOfUse(true);
    };

    const renderStepTwoHeader = () => {
        return (
            <div>
                <div className="font-medium text-center sm:font-semibold text-md sm:text-2xl">
                    Get access to top CS interns from Stanford, Berkeley, Waterloo, Ivy Leagues, etc.
                </div>
            </div>
        );
    };

    const stepOne = (
        <form onSubmit={() => setStep(2)}>
            {renderStepTwoHeader()}
            <div className="mt-5" />
            <TextField required onChange={(e) => setName(e.target.value)} text={'Your name'} type={'text'} id={'name'} name={'name'} value={name} />
            <div className="mt-3" />
            <TextField
                required
                onChange={(e) => setCompanyName(e.target.value)}
                text={'Company name'}
                type={'text'}
                id={'company-name'}
                name={'company-name'}
                value={companyName}
            />
            <div className="mt-3" />
            <TextField required onChange={(e) => setEmail(e.target.value)} text={'Work email'} type={'email'} id={'email'} name={'email'} value={email} />

            <div className="mt-10" />
            <div className="grid grid-cols-1 ">
                <button
                    type="submit"
                    className="inline-flex items-center px-10 py-2 mx-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </form>
    );

    const stepTwo = (
        <div>
            <div className="font-medium text-center text-md sm:font-semibold sm:text-2xl">Tell us more about your role!</div>
            <div className="mt-5" />
            <TextField
                required
                onChange={(e) => setJobTitle(e.target.value)}
                text={'Job title'}
                altText={'i.e. Fullstack Engineer, iOS/Swift Engineer'}
                type={'text'}
                id={'job-title'}
                name={'job-title'}
            />
            <div className="mt-3" />
            <TextField
                required
                onChange={(e) => setJobLocation(e.target.value)}
                text={'Job location'}
                type={'text'}
                id={'job-location'}
                name={'job-location'}
                altText={'Please indicate if remote is allowed'}
            />
            <div className="mt-3" />
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<CustomDateInput />} />
            <div className="mt-3" />
            <TextArea onChange={(e) => setJobDescription(e.target.value)} text={'Job description'} altText={'Optional'} />
            <div className="mt-10" />
            <div className="grid grid-cols-2 gap-4">
                <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="inline-flex items-center px-10 py-2 ml-auto text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none "
                >
                    Back
                </button>
                <button
                    type="submit"
                    onClick={validateStepTwo}
                    className="inline-flex items-center px-10 py-2 mr-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </div>
    );

    const stepThree = (
        <div>
            <div className="font-medium text-center sm:font-semibold text-md sm:text-2xl">
                Last step to finding your next hire! Complete your account to get access to your matched candidates.
            </div>
            <div className="mt-5" />
            <Password password={password} setPassword={setPassword} />
            <div className="mt-2" />

            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="terms"
                        name="terms"
                        onClick={() => setAgreedToTerms(!agreedToTerms)}
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label htmlFor="terms" className="block ml-2 text-sm text-gray-900">
                        I agree to the{' '}
                        <div onClick={openTerms} style={{ color: '#4749e0', display: 'inline-block', zIndex: '100', cursor: 'pointer' }}>
                            Terms & Conditions
                        </div>
                    </label>
                </div>
            </div>
            {termsValidate ? (
                <div>
                    <div className="mt-2" />
                    <p className="w-full text-xs error">You must accept the Terms & Conditions</p>
                </div>
            ) : (
                ''
            )}
            <div className="mt-10" />

            <div className="text-center">
                <button
                    type="button"
                    onClick={createUser}
                    className="inline-flex items-center px-10 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Continue
                </button>
            </div>
        </div>
    );

    if (loading) return <Loader />;
    else
        return (
            <div className="mx-6 mt-5 mb-5 sm:mx-12 sm:mt-10">
                <TermsModal />
                {step === 1 && (
                    <div>
                        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-6 sm:mt-32">
                            <div className="hidden sm:block">
                                <Sketching />
                            </div>
                            <div className="sm:mx-14 sm:my-auto">{stepOne}</div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <div className="mt-8 sm:mt-12" />
                        <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                            <div className="hidden sm:block ">
                                <TeamUp />
                            </div>
                            <div className="sm:mx-14 sm:my-auto">{stepTwo}</div>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <div className="mt-8 sm:mt-32" />
                        <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                            <div className="hidden sm:block ">
                                <People />
                            </div>
                            <div className="sm:mx-14 sm:my-auto">
                                {showErrorAlert && (
                                    <div className="mb-5">
                                        <ErrorAlert errorMessage={alertMsg} />
                                    </div>
                                )}
                                {stepThree}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
}
export default connect(null, { openTermsOfUse })(Registration);
