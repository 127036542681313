/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon, DocumentRemoveIcon } from '@heroicons/react/solid';
import { CandidateCard } from '../../company/CandidateCard';

//15 Redacted Users

const defaultPeople = [
    {
        name: '████████ ███',
        title: 'Electrical Engineering & Computer Science',
        school: 'UC Berkeley 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['Machine Learning', 'Quantitative Analysis', 'Statistics', 'Java', 'Python', 'SQL', 'Tensorflow'],
        employments: [{ position: 'SWE Intern' }, { position: 'Research Intern' }, { position: 'USACO Platinum, Top 200' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '█████ ██████',
        title: 'Computer Science',
        school: 'Stanford University 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['Python', 'C', 'C++', 'Tensorflow', 'Keras', 'Machine Learning'],
        employments: [{ position: 'Software Engineer, Tesla' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '█████ █████',
        title: 'MET (EECS + Business)',
        school: 'UC Berkeley 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['NodeJS', 'Python', 'C', 'Solidity', 'Rust'],
        employments: [{ position: 'Amazon SDE' }, { position: 'Software Engineer at Apple' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '███ ████',
        title: 'Computer Engineering',
        school: 'University of Toronto 2026',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['Machine Learning', 'Python', 'Matlab', 'C', 'C++', 'PyTorch', 'OpenCV'],
        employments: [{ position: 'Open Source Developer' }, { position: 'Research Engineering Intern' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '██ ██████',
        title: 'Mathematics',
        school: 'University of Waterloo 2026',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['AngularJS', 'ReactJS', 'NodeJS', 'Python', 'Data Science', 'AI'],
        employments: [{ position: 'Frontend Developer' }, { position: 'Frontend Developer' }, { position: 'Frontend Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '██████ ████',
        title: 'Computer Science',
        school: 'Carleton University 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'Python', 'C', 'Java', 'MongoDB', 'PostgreSQL'],
        employments: [{ position: 'Backend Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },

    {
        name: '███████ █████',
        title: 'Computer Science',
        school: 'UC Berkeley 2024',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['Python', 'Java', 'Javascript', 'ReactJS', 'Typescript', 'Tensorflow'],
        employments: [{ position: 'SWE Intern' }, { position: 'SWE Intern' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '████████ ███',
        title: 'Computer Science',
        school: 'University of British Columbia 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'Typescript', 'Salesforce CRM'],
        employments: [{ position: 'Web Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '████ ██',
        title: 'Computer Science',
        school: 'Carleton University 2025',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'Typescript', 'C', 'Python'],
        employments: [{ position: 'Backend Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '█████ █████',
        title: 'MET (EECS + Business)',
        school: 'UC Berkeley 2024',
        availability: ['Spring 2024', 'Summer 2024', 'Fall 2024'],
        skills: ['NodeJS', 'Python', 'C', 'Solidity', 'Rust'],
        employments: [{ position: 'SWE' }, { position: 'Developer Consultant' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '█████ ██████',
        title: 'Computer Science',
        school: 'Stanford University 2026',
        availability: ['Summer 2024', 'Fall 2024'],
        skills: ['Python', 'C', 'C++', 'Tensorflow', 'Keras', 'Machine Learning'],
        employments: [{ position: 'Head of Data & Machine Learning' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '██████ ██████',
        title: 'Information Systems',
        school: 'Carnegie Mellon University 2025',
        availability: ['Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'PostgreSQL', 'VueJS', 'Firebase', 'AWS'],
        employments: [{ position: 'SWE Intern' }, { position: 'Frontend Developer' }, { position: 'Web Development Intern' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '██████ ████',
        title: 'UPenn M&T (Computer Science + Finance)',
        school: 'University of Pennsylvania 2025',
        availability: ['Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'Rust', 'Solidity', 'Web3', 'Smart Contracts'],
        employments: [{ position: 'SWE' }, { position: 'Web3 Software Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
        name: '█████ ███████',
        title: 'Computer Science',
        school: 'University of Toronto 2025',
        availability: ['Summer 2024', 'Fall 2024'],
        skills: ['ReactJS', 'NodeJS', 'Solidity', 'MongoDB', 'PostgreSQL', 'Smart Contracts'],
        employments: [{ position: 'Founding Engineer' }, { position: 'Lead Developer' }, { position: 'Web Application Developer' }],
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
];

export default function DirectoryGrid({ people = defaultPeople }) {
    return (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {people.map((person) => (
                <CandidateCard candidate={person} preview />
            ))}
        </ul>
    );
}
