import { combineReducers } from "redux";

const defaultState = {
    showPaymentSummary: false,
    employeeName: '',
    employeeTitle: '',
    employeeEmail: '',
    transactionValue: '',
    transactionDescription: '',
    transactionCurrency: '',
    transactionTimestamp: '',
    transactionId: '',
    invoiceAdded: false
  };

const paymentSummaryReducer = (state = defaultState, action) => {
    switch(action.type){
        case 'SHOW_PAYMENT_SUMMARY':
            return {
                ...state,
                showPaymentSummary: action.payload
            }
        case 'SHOW_PAYMENT_SUMMARY':
            return {
                ...state,
                showPaymentSummary: action.payload
        }
        case 'STORE_EMPLOYEE_NAME':
            return {
                ...state,
                employeeName: action.payload
        }
        case 'STORE_EMPLOYEE_TITLE':
            return {
                ...state,
                employeeTitle: action.payload
        }
        case 'STORE_EMPLOYEE_EMAIL':
            return {
                ...state,
                employeeEmail: action.payload
        }
        case 'STORE_EMPLOYEE_TRANSACTION_VALUE':
            return {
                ...state,
                transactionValue: action.payload
        }
        case 'STORE_EMPLOYEE_TRANSACTION_DESCRIPTION':
            return {
                ...state,
                transactionDescription: action.payload
        }
        case 'STORE_EMPLOYEE_TRANSACTION_CURRENCY':
            return {
                ...state,
                transactionCurrency: action.payload
        }
        case 'STORE_EMPLOYEE_TRANSACTION_TIMESTAMP':
            return {
                ...state,
                transactionTimestamp: action.payload
        }
        case 'STORE_TRANSACTION_ID':
            return {
                ...state,
                transactionId: action.payload
        }
        case 'INVOICE_ADDED':
            return {
                ...state,
                invoiceAdded: action.payload
        }
    }
    return state;
 }

 const employeeSummaryReducer = (employeeSummary = false, action) => {
    if (action.type === 'SHOW_EMPLOYEE_SUMMARY'){
        return action.payload;
    }
    return employeeSummary;
 }
 const openNewEmployeePageReducer = (openPage = false, action) => {
    if (action.type === 'SET_NEW_EMPLOYEE_PAGE'){
        return action.payload;
    }
    return openPage;
 }
 const openNewPaymentPageReducer = (openPage = false, action) => {
    if (action.type === 'SET_NEW_PAYMENT_PAGE'){
        return action.payload;
    }
    return openPage;
 }
 const JobListingReducer = (id = null, action) => {
    if (action.type === 'STORE_JOB_LISTING_ID'){
        return action.payload;
    }
    return id;
 }
 const openTermsReducer = (open = null, action) => {
    if (action.type === 'OPEN_TERMS_OF_USE'){
        return action.payload;
    }
    return open;
 }

 export default combineReducers({
    paymentSummary: paymentSummaryReducer,
    employeeSummary: employeeSummaryReducer,
    openNewEmployeePage: openNewEmployeePageReducer,
    openNewPaymentPage: openNewPaymentPageReducer,
    jobListingId: JobListingReducer,
    isTermsOfUseOpen: openTermsReducer
 });