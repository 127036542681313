export default function TextField(props) {
    const { text, type, id, name, onChange, value, defaultValue, required, min, altText, validate } = props;

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">{text}</label>
            <label className="block text-xs text-gray-500">{altText}</label>
            <div className="mt-1">
                <input
                    type={type}
                    onChange={onChange}
                    name={id}
                    id={name}
                    defaultValue={defaultValue}
                    value={value}
                    required={required}
                    min={min}
                    className={classNames(
                        'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
                        validate ? 'border-red-500 border-2' : ''
                    )}
                />
            </div>
        </div>
    );
}
