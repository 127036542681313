export const showPaymentSummary = (showing) => { 
    return {
        type: 'SHOW_PAYMENT_SUMMARY',
        payload: showing
    };
};
export const showEmployeeSummary = (showing) => { 
    return {
        type: 'SHOW_EMPLOYEE_SUMMARY',
        payload: showing
    };
};
export const storeEmployeeName = (name) => { 
    return {
        type: 'STORE_EMPLOYEE_NAME',
        payload: name
    };
};
export const storeEmployeeTitle = (title) => { 
    return {
        type: 'STORE_EMPLOYEE_TITLE',
        payload: title
    };
};
export const storeEmployeeEmail = (email) => { 
    return {
        type: 'STORE_EMPLOYEE_EMAIL',
        payload: email
    };
};
export const storeTransactionValue = (transactionValue) => { 
    return {
        type: 'STORE_EMPLOYEE_TRANSACTION_VALUE',
        payload: transactionValue
    };
};
export const storeTransactionDescription = (transactionDescription) => { 
    return {
        type: 'STORE_EMPLOYEE_TRANSACTION_DESCRIPTION',
        payload: transactionDescription
    };
};
export const storeTransactionCurrency = (transactionCurrency) => { 
    return {
        type: 'STORE_EMPLOYEE_TRANSACTION_CURRENCY',
        payload: transactionCurrency
    };
};
export const storeTransactionTimestamp = (transactionTimestamp) => { 
    return {
        type: 'STORE_EMPLOYEE_TRANSACTION_TIMESTAMP',
        payload: transactionTimestamp
    };
};
export const storeTransactionId = (id) => { 
    return {
        type: 'STORE_TRANSACTION_ID',
        payload: id
    };
};
export const addInvoiceToStore = (isAdded) => { 
    return {
        type: 'INVOICE_ADDED',
        payload: isAdded
    };
};
export const newEmployeePageOpen = (isOpen) => { 
    return {
        type: 'SET_NEW_EMPLOYEE_PAGE',
        payload: isOpen
    };
};
export const newPaymentPageOpen = (isOpen) => { 
    return {
        type: 'SET_NEW_PAYMENT_PAGE',
        payload: isOpen
    };
};
export const storeJobListingId = (id) => {
    return{
        type: 'STORE_JOB_LISTING_ID',
        payload: id
    }
}

export const openTermsOfUse = (open) => {
    return{
        type: 'OPEN_TERMS_OF_USE',
        payload: open
    }
}