export default  [
    {
        "name": "First Person",
        "role": "Developer",
        "currency": "USDC",
        "value": 1235
    },
    {
        "name": "Third Person",
        "role": "Project Manager",
        "currency": "USDC",
        "value": 2450
    },
    {
        "name": "Second Person",
        "role": "Developer",
        "currency": "USDC",
        "value": 245
    },
    {
        "name": "Fourth Person",
        "role": "Developer",
        "currency": "USDC",
        "value": 450
    },
    {
        "name": "Fifth Person",
        "role": "Designer",
        "currency": "USDC",
        "value": 645
    }
]