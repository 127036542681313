import { useState } from 'react';

export const useRefetch = () => {
    const [shouldRefetch, setShouldRefetch] = useState(0);
    const refetch = () => setShouldRefetch((prev) => prev + 1);

    return [shouldRefetch, refetch];
};


