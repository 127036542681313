import { connect } from 'react-redux';
import { showPaymentSummary } from "../../actions";
import { PaperClipIcon } from '@heroicons/react/solid'
import { db, auth, storage } from '../../firebase/config';
import { getStorage, ref, getDownloadURL} from 'firebase/storage';

const PaymentSummary = (props) => {

    const close = () => {
        props.showPaymentSummary(false)
    }
    const downloadInvoice = () => {
        const invoiceRef = ref(storage, `user/invoices/${props.summary.transactionId}`);
        getDownloadURL(invoiceRef)
        .then((url) => {
            var hiddenElement = document.createElement('a');
            hiddenElement.href = url;
            hiddenElement.target = '_blank';
            hiddenElement.download = `invoice-${props.summary.transactionId}`;
            hiddenElement.click();
            console.log(url)
        })
        .catch((error) => {
            console.log(error.message)
        });
    }
    return (
        <div>
                {props.summary.showPaymentSummary ? 
                    <div className='summary-dim' onClick={() => props.showPaymentSummary(false)}>
                        <div className='summary-container'>
                        <div onClick={(e) => e.stopPropagation()} className="rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 payment-summary">
                            <button
                                onClick={close}
                                type="button"
                                className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 close-summary-btn"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-40 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                            <dt className="text-sm  font-medium text-gray-500">Full name</dt>
                            <dd className="mt-1 text-sm text-gray-900">{props.summary.employeeName}</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Role</dt>
                            <dd className="mt-1 text-sm text-gray-900">{props.summary.employeeTitle}</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900">{props.summary.employeeEmail}</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Transfer</dt>
                            <dd className="mt-1 text-sm text-gray-900">{props.summary.transactionValue} {props.summary.transactionCurrency}</dd>
                            </div>
                            <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Payment description</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {props.summary.transactionDescription}
                            </dd>
                            </div>
                            {props.summary.invoiceAdded ?
                            <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                    <div className="w-0 flex-1 flex items-center">
                                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span className="ml-2 flex-1 w-0 truncate">{`${props.summary.employeeName}'s Invoice`}</span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                    <button onClick={() => downloadInvoice()} className="font-medium text-indigo-600 hover:text-indigo-500">
                                        View
                                    </button>
                                    </div>
                                </li>
                                </ul>
                            </dd>
                            </div>
                            :
                            ''
                            }
                        </dl>
                        </div>
                    </div>

                    </div>
            </div>
            :
            ''
            }
        </div>
        
    );
}
const mapStateToProps = (state) => {
    return {summary: state.paymentSummary};
};
export default connect(mapStateToProps, {showPaymentSummary})(PaymentSummary);