import { UserIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { 
    showPaymentSummary,
    storeEmployeeName,
    storeEmployeeTitle,
    storeEmployeeEmail,
    storeTransactionValue,
    storeTransactionDescription,
    storeTransactionCurrency,
    storeTransactionTimestamp,
    storeTransactionId,
    addInvoiceToStore
} from '../../actions'
import { connect } from 'react-redux';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db, auth } from '../../firebase/config';

import "../../style/payments.css"
import { useEffect, useState } from 'react';

function PaymentsGrid(props) {

    const [payments, setPayments] = useState([]);
    const [searchValue, setSearchValue] = useState();

    const openSummary = (payment) => {
        props.showPaymentSummary(true);
        props.storeEmployeeName(payment.name);
        props.storeEmployeeTitle(payment.title)
        props.storeEmployeeEmail(payment.email)
        props.storeTransactionValue(payment.amount)
        props.storeTransactionDescription(payment.description)
        props.storeTransactionCurrency(payment.cryptocurrency)
        props.storeTransactionTimestamp(payment.timestamp)
        props.storeTransactionId(payment.id)
        props.addInvoiceToStore(payment.invoiceAdded)
    }
    const renderTableHeadClassname = () => {
        if(showPaymentSummary){
            return "bg-gray-50 thead-back"
        }else{
            return "bg-gray-50"
        }
    }

    const convertToNormalDate = (epoch) => {
        const date = new Date(epoch);
        const dateString = date.toLocaleString();
        return dateString;
    }

    useEffect(() => {
        let q = ''
        try{
            const ref = collection(db, 'users', auth.currentUser.uid, 'payments')
            if(searchValue == "ETH" || searchValue == "USDC"){
              console.log("search crypto")
              q = query(ref, where('cryptocurrency', '==', searchValue));
            }else if(searchValue){
              q = query(ref, where('name', '>=', searchValue), where('name', '<=', searchValue + '\uf8ff'));
            }else{
              q = query(ref, orderBy("timestamp", "desc"));
            }
        }catch (e){
            console.log(e.message)
        }
        const payments = [];
        const fetchPayments = async () => {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                payments.push({
                    "name": doc.data().name,
                    "title": doc.data().title,
                    "email": doc.data().email,
                    "walletAddress": doc.data().walletAddress,
                    "amount": doc.data().amount,
                    "cryptocurrency": doc.data().cryptocurrency,
                    "description": doc.data().description,
                    "timestamp": doc.data().timestamp,
                    "id": doc.id,
                    "invoiceAdded": doc.data().invoiceAdded
                })
              });
              setPayments(payments);
        }
        if(searchValue){
          const timeoutId = setTimeout(() => {
            fetchPayments();
          }, 500)
          return () => {
            clearTimeout(timeoutId);
          }
        }else{
          fetchPayments();
        }
    }, [searchValue]);

    const handleSearchbar = async (event) => {
      setSearchValue(event.target.value);
    }

    return (
        <div className="mt-8 flex flex-col">
        <div className='quick-search-container'>
          <div className="mt-1 relative flex items-center">
            <input
            placeholder='Quick Search'
              onChange={(event) => setSearchValue(event.target.value)}
              value={searchValue}
              type="text"
              name="search"
              id="search"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            </div>
          </div>
        </div>
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">

              <table className="min-w-full border-separate table" style={{ borderSpacing: 0 }}>
                <thead className={renderTableHeadClassname()}>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Date & Time
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Recipient Name
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {payments.map((payment) => (
                    <tr key={payment.timestamp} onClick = {() => openSummary(payment)} className="payment-row">
                      <td
                        className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8'
                      >
                        {convertToNormalDate(payment.timestamp)}
                      </td>
                      <td
                        className='whitespace-nowrap px-3 py-4 text-sm hidden sm:table-cell'
                      >
                        {payment.name}
                      </td>
                      <td
                        className='whitespace-nowrap px-3 py-4 text-sm'
                      >
                        {payment.description}
                      </td>
                      <td
                        className='relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 value-info'
                      >
                        <div href="#" className="value-img-container">
                        <div className="flex-shrink-0">
                            {payment.cryptocurrency == "ETH" ?
                        <img className="h-6 w-6 rounded-full value-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/1200px-Ethereum-icon-purple.svg.png" alt="" />
                        :
                        <img className="h-6 w-6 rounded-full value-img" src="https://static.coinpaprika.com/coin/usdc-usd-coin/logo.png?rev=10562849" alt="" />
                        }
                        </div>
                        <div className='recent-payment-pay'>
                            <p className='recent-payment-currency'>{payment.cryptocurrency}</p>
                            <p className='recent-payment-value'>{payment.amount}</p>
                        </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {summary: state.paymentSummary};
};

export default connect(mapStateToProps, {
    showPaymentSummary,
    storeEmployeeName,
    storeEmployeeTitle,
    storeEmployeeEmail,
    storeTransactionValue,
    storeTransactionDescription,
    storeTransactionCurrency,
    storeTransactionTimestamp,
    storeTransactionId,
    addInvoiceToStore
})(PaymentsGrid);