import { useState, useEffect, forwardRef, useRef, useCallback } from 'react';
import TextField from '../form/TextField';
import Select from '../form/Select';
import Loader from '../Loader';
import ErrorAlert from '../modals/ErrorAlert';
import TermsModal from '../modals/TermsModal';
import Password from '../form/Password';
import MultiselectComboBox from '../form/MultiSelectComboBox';
import { ReactComponent as CV } from '../assets/CV.svg';
import { ReactComponent as Availability } from '../assets/Availability.svg';
import { ReactComponent as OnboardingCV } from '../assets/OnboardingCV.svg';
import { ReactComponent as Sketching } from '../assets/Sketching.svg';
import { ReactComponent as Skills } from '../assets/Skills.svg';
import { ReactComponent as Interview } from '../assets/Interview.svg';
import { useNavigate, Link } from 'react-router-dom';
import { ref, uploadBytes } from 'firebase/storage';
import { setDoc, doc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth, storage } from '../../firebase/config';
import { roleOptions } from '../variables/RoleOptions';
import { openTermsOfUse } from '../../actions';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import '../../style/onboarding.css';

import { availabilityOptions } from '../variables/AvailabilityOptions';
import { graduationYearOptions, graduationMonthOptions } from '../variables/Graduation';
import { schoolOptions } from '../variables/SchoolOptions';



import CustomSkillSelect from '../form/CustomSkillSelect'; // used to make skill selection dynamic (March 5 2024)


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Registration(props) {
    const [step, setStep] = useState(5);
    const [password, setPassword] = useState('');
    const [availabilitySelected, setAvailabilitySelected] = useState([]);
    const [skillsSelected, setSkillsSelected] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [graduationYear, setGraduationYear] = useState('');
    const [graduationMonth, setGraduationMonth] = useState('');
    const [graduationMonthValidate, setGraduationMonthValidate] = useState(false);
    const [graduationYearValidate, setGraduationYearValidate] = useState(false);
    const [school, setSchool] = useState('');
    const [loading, setLoading] = useState(false);
    const [skillsValidate, setSkillsValidate] = useState(false);
    const [availabilityValidate, setAvailabilityValidate] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [termsValidate, setTermsValidate] = useState(false);
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileValidate, setFileValidate] = useState(false);

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const navigate = useNavigate();

    const validateStepOne = (event) => {
        setGraduationYearValidate(false);
        setGraduationMonthValidate(false);
        event.preventDefault();
        if (!graduationYear) {
            setGraduationYearValidate(true);
            return;
        }
        if (!graduationMonth) {
            setGraduationMonthValidate(true);
            return;
        }
        setStep(2);
    };

    const validateStepTwo = () => {
        if (!fileName) {
            setFileValidate(true);
            return;
        }
        setFileValidate(false);
        setStep(3);
    };

    const validateStepThree = () => {
        if (skillsSelected.length === 0) {
            setSkillsValidate(true);
            return;
        }
        setSkillsValidate(false);
        setStep(4);
    };

    const validateStepFour = () => {
        if (!availabilitySelected) {
            setAvailabilityValidate(true);
            return;
        }
        setStep(5);
    };

    const createUser = () => {
        if (!agreedToTerms) {
            setTermsValidate(true);
            return;
        }
        setTermsValidate(false);
        if (password) {
            setLoading(true);
            const userData = {
                full_name: name,
                email: email,
                graduation_month: graduationMonth,
                graduation_year: graduationYear,
                school: school,
                roles: skillsSelected,
                availability: availabilitySelected,
                resume_name: fileName,
            };
            createUserWithEmailAndPassword(auth, userData.email, password)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    const storageRef = ref(storage, `talent/resumes/${user.uid}`);
                    await setDoc(doc(db, 'talent', user.uid), userData);
                    const metadata = {
                        contentType: 'application/pdf',
                      };
                    await uploadBytes(storageRef, file, metadata);
                    setLoading(false);
                    navigate('/home');
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setAlertMsg(errorMessage);
                    setShowErrorAlert(true);
                });
        }
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    const openTerms = (event) => {
        event.preventDefault();
        props.openTermsOfUse(true);
    };

    const handleChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };
    const drop = useCallback((node) => {
        if (node !== null) {
            node.addEventListener('dragover', handleDragOver);
            node.addEventListener('drop', handleDrop);
        }
    });
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const { files } = await e.dataTransfer;
        const droppedFile = files[0];
        setFile(droppedFile);
        setFileName(droppedFile.name);
        e.stopPropagation();
    };

    const stepOne = (
        <form onSubmit={validateStepOne}>
            <div>
                <div>
                    <div className="flex justify-center" id="mobile">
                        <div style={{ fontSize: '5rem' }}>🙌🏼</div>
                    </div>
                    <div className="text-2xl font-medium font-semibold text-center text-md">
                        Congrats, welcome to Sonder! <br />
                        <div className="mt-2"></div>
                        <p className="text-lg font-medium text-gray-500 sm:text-xl">
                            In less than 1 minute, you'll be ready to start receiving internship offers.
                        </p>
                    </div>
                </div>
            </div>

            <TextField required onChange={(e) => setName(e.target.value)} text={'Full Name'} type={'text'} id={'name'} name={'name'} value={name} />
            <div className="mt-3" />
            <div className="mobile">
                <div className="mt-4" />
                <div className="flex">
                    <div className="w-40 mr-8">
                        <Select
                            validate={graduationMonthValidate}
                            options={graduationMonthOptions}
                            selected={graduationMonth}
                            setSelected={setGraduationMonth}
                            text={'Graduation Month'}
                        />
                    </div>
                    <div className="w-40">
                        <Select
                            validate={graduationYearValidate}
                            options={graduationYearOptions}
                            selected={graduationYear}
                            setSelected={setGraduationYear}
                            text={'Graduation Year'}
                        />
                    </div>
                </div>
                <div className="mt-4" />
                <TextField
                    required
                    onChange={(e) => setSchool(e.target.value)}
                    text={'Your school'}
                    type={'text'}
                    id={'school'}
                    name={'school'}
                    value={school}
                />
            </div>
            <div className="mt-3" />
            <div className="desktop">
                <TextField
                    required
                    onChange={(e) => setSchool(e.target.value)}
                    text={'Your school'}
                    type={'text'}
                    id={'school'}
                    name={'school'}
                    value={school}
                />
                <div className="mt-4" />
                <div className="flex">
                    <div className="w-64 mr-10">
                        <Select
                            validate={graduationMonthValidate}
                            options={graduationMonthOptions}
                            selected={graduationMonth}
                            setSelected={setGraduationMonth}
                            text={'Graduation Month'}
                        />
                    </div>
                    <div className="w-64 ">
                        <Select
                            validate={graduationYearValidate}
                            options={graduationYearOptions}
                            selected={graduationYear}
                            setSelected={setGraduationYear}
                            text={'Graduation Year'}
                        />
                    </div>
                </div>
            </div>
            <div className="desktop">
                <div className="mt-10" />
            </div>
            <div className="mt-6" />
            <div className="grid grid-cols-2 gap-4">
                <button
                    type="button"
                    onClick={() => navigate('/')}
                    className="inline-flex items-center px-10 py-2 ml-auto text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none "
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="inline-flex items-center px-10 py-2 mr-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </form>
    );
    const stepTwo = (
        <div>
            <div>
                <div className="flex justify-center" id="mobile">
                    <div style={{ fontSize: '5rem' }}>📄</div>
                </div>
                <div className="text-2xl font-medium font-semibold text-center text-md">Submit your resume</div>
            </div>
            <div className="mt-12"></div>
            <div
                ref={drop}
                className={classNames(
                    fileValidate
                        ? 'mt-1 max-w-2xl flex justify-center px-6 pt-5 pb-6 border-2 border-red-300 border-dashed rounded-md'
                        : 'mt-1 max-w-2xl flex justify-center px-6 pt-5 pb-6 border-2 border-grey-300 border-dashed rounded-md'
                )}
            >
                <div className="space-y-1 text-center">
                    <CV />
                    <div className="text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                            {!fileName && <span>Upload a file</span>}
                            {fileName && <span>{fileName}</span>}
                            <input onChange={handleChange} multiple={false} id="file-upload" name="file-upload" type="file" accept=".pdf" className="sr-only" />
                        </label>
                    </div>
                </div>
            </div>
            <div className="mt-10" />
            <div className="grid grid-cols-2 gap-4">
                <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="inline-flex items-center px-10 py-2 ml-auto text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none "
                >
                    Back
                </button>
                <button
                    type="submit"
                    onClick={validateStepTwo}
                    className="inline-flex items-center px-10 py-2 mr-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </div>
    );

    const stepThree = (
        <div>
            <div className="flex justify-center" id="mobile">
                <div style={{ fontSize: '5rem' }}>🎯</div>
            </div>
            <div className="text-2xl font-medium font-semibold text-center text-md">Which role(s) are you looking for?</div>
            <div className="mt-8" />
            <label className="block text-sm font-medium text-gray-700">I would like to work as a</label>
            <CustomSkillSelect validate={skillsValidate} skillsSelected={skillsSelected} setSkillsSelected={setSkillsSelected} options={roleOptions} />
            <div className="mt-5" />

            <div className="mt-10" />
            <div className="grid grid-cols-2 gap-4">
                <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="inline-flex items-center px-10 py-2 ml-auto text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none "
                >
                    Back
                </button>
                <button
                    type="submit"
                    onClick={validateStepThree}
                    className="inline-flex items-center px-10 py-2 mr-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </div>
    );

    const stepFour = (
        <div>
            <div className="flex justify-center" id="mobile">
                <div style={{ fontSize: '5rem' }}>🗓</div>
            </div>
            <div className="text-2xl font-medium font-semibold text-center text-md">What is your availability to intern?</div>
            <div className="mt-8" />
            <MultiselectComboBox
                validate={availabilityValidate}
                options={availabilityOptions}
                skillsSelected={availabilitySelected}
                setSkillsSelected={setAvailabilitySelected}
            />
            <div className="mt-10" />
            <div className="grid grid-cols-2 gap-4">
                <button
                    type="button"
                    onClick={() => setStep(3)}
                    className="inline-flex items-center px-10 py-2 ml-auto text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none "
                >
                    Back
                </button>
                <button
                    type="submit"
                    onClick={validateStepFour}
                    className="inline-flex items-center px-10 py-2 mr-auto text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Next
                </button>
            </div>
        </div>
    );

    const stepFive = (
        <div>
            <div className="flex justify-center" id="mobile">
                <div style={{ fontSize: '5rem' }}>🎉</div>
            </div>
            <div className="mt-2" />
            <div className="text-2xl font-medium text-center md:text-4xl">Find your dream internship.</div>
            <div className="mt-5" />
            <div className="font-normal text-center text-md md:text-xl">Create an account and we'll match your profile with companies in our network.</div>
            <div className="mt-5" />
            <TextField required onChange={(e) => setEmail(e.target.value)} text={'Email'} type={'email'} id={'email'} name={'email'} value={email} />
            <div className="mt-3" />
            <Password password={password} setPassword={setPassword} />
            <div className="mt-2" />
            {termsValidate ? (
                <div>
                    <div className="mt-2" />
                    <p className="w-full text-xs error">You must accept our Terms & Conditions</p>
                </div>
            ) : (
                ''
            )}
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="terms"
                        name="terms"
                        onClick={() => setAgreedToTerms(!agreedToTerms)}
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label htmlFor="terms" className="block ml-2 text-sm text-gray-900">
                        I agree to the{' '}
                        <div onClick={openTerms} style={{ color: '#4749e0', display: 'inline-block', zIndex: '100', cursor: 'pointer' }}>
                            Terms & Conditions
                        </div>
                    </label>
                </div>
            </div>
            <div className="mt-6" />

            <div className="text-center">
                <button
                    type="button"
                    onClick={createUser}
                    className="inline-flex items-center px-10 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                >
                    Continue
                </button>
            </div>
        </div>
    );

    if (loading) return <Loader />;
    else
        return (
            <div className="onboarding-page">
                <div className="mx-6 mt-2 mb-5 sm:mx-12 sm:mt-10">
                    <TermsModal />
                    {step === 1 && (
                        <div>
                            <div className="mt-32 desktop" />
                            <div className="mt-2 mobile" />
                            <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                                <div className="hidden sm:block ">
                                    <Sketching />
                                </div>
                                <div className="sm:mx-14 sm:my-auto">{stepOne}</div>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <div className="mt-32 desktop" />
                            <div className="mt-8 mobile" />
                            <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                                <div className="hidden sm:block ">
                                    <OnboardingCV />
                                </div>
                                <div className="sm:mx-14 sm:my-auto">{stepTwo}</div>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <div className="mt-32 desktop" />
                            <div className="mt-8 mobile" />
                            <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                                <div className="hidden sm:block ">
                                    <Skills />
                                </div>
                                <div className="sm:mx-14 sm:my-auto">{stepThree}</div>
                            </div>
                        </div>
                    )}
                    {step === 4 && (
                        <div>
                            <div className="mt-32 desktop" />
                            <div className="mt-8 mobile" />
                            <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                                <div className="hidden sm:block ">
                                    <Availability />
                                </div>
                                <div className="sm:mx-14 sm:my-auto">{stepFour}</div>
                            </div>
                        </div>
                    )}
                    {step === 5 && (
                        <div>
                            <div className="mt-32 desktop" />
                            <div className="mt-2 mobile" />
                            <div className="sm:grid sm:grid-cols-2 sm:gap-6">
                                <div className="hidden sm:block ">
                                    <Interview />
                                </div>
                                <div className="sm:mx-14 sm:my-auto">
                                    {showErrorAlert && (
                                        <div className="mb-5">
                                            <ErrorAlert errorMessage={alertMsg} />
                                        </div>
                                    )}
                                    {stepFive}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
}
export default connect(null, { openTermsOfUse })(Registration);
