import MailchimpSubscribe from 'react-mailchimp-subscribe';

export default function Footer(props) {
    const { color } = props;
    const postUrl = `https://usesonder.us10.list-manage.com/subscribe/post?u=005958eca48e2f44c16f4f6c0&amp;id=cd6e217922`;

    return (
        <div className={color}>
            <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                <div>
                    <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">Stay in the loop with sonder</h3>
                </div>
                <MailchimpSubscribe
                    url={postUrl}
                    render={({ subscribe, status, message }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                subscribe({
                                    EMAIL: event.target.email.value,
                                });
                            }}
                            className="mt-4 sm:flex sm:max-w-md lg:mt-0"
                        >
                            {status !== 'success' && (
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        required
                                        className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400 sm:max-w-xs"
                                        placeholder="Enter your email"
                                    />
                                </div>
                            )}
                            {status === 'success' && <div className="my-auto mr-4 text-gray-400">Thank you for subscribing!</div>}
                            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
            <div className="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex mt-0 text-sm text-white lg:flex-shrink-0">Made with love in San Francisco ❤️</div>
                <a href="https://twitter.com/usesonder" className="text-gray-400 hover:text-gray-500">
                    <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                </a>
            </div>
        </div>
    );
}
