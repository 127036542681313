import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmPasswordReset, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { db, auth } from '../firebase/config';
import ErrorAlert from './modals/ErrorAlert';

export default function SignIn() {

    const [alertMsg, setAlertMsg] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [email, setEmail] = useState('');
    const [emailValidate, setEmailValidate] = useState(false);


    const navigate = useNavigate();

    const sendVerificationEmail = () => {
        const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(!(email.match(mailFormat))){
            setEmailValidate(true);
            return
        }
        sendPasswordResetEmail(auth, email)
        .then(function () {
            alert('Please check your email(if you cannot find an email please check spam folder ;)')
            navigate("/signin")
        }).catch(function (e) {
            console.log(e)
        }) 
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    {showErrorAlert && (
                        <div className="mb-5">
                            <ErrorAlert errorMessage={alertMsg} />
                        </div>
                    )}
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Recover your account</h2>
                </div>
                <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    {emailValidate ? 
                                        <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-red-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />  
                                        :                                  
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="mt-5"></div>
                            <div>
                                <button
                                    type="button"
                                    onClick={sendVerificationEmail}
                                    className="cursor-pointer w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    Send Verification Code
                                </button>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}
