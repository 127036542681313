export default function TextArea(props) {
    const { text, altText, onChange, value } = props;

    return (
        <div>
            <label htmlFor="textarea" className="block text-sm font-medium text-gray-700">
                {text}
            </label>
            <label className="block text-xs text-gray-500">{altText}</label>
            <div className="mt-1">
                <textarea
                    rows={3}
                    onChange={onChange}
                    name="textarea"
                    id="textarea"
                    className="resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={value}
                />
            </div>
        </div>
    );
}
