/*
Use this: only need string for combobox
*/
import { useState, useEffect } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function MultiselectComboBox(props) {
    const { options, skillsSelected, setSkillsSelected, validate } = props;
    const [query, setQuery] = useState('');

    const removeSelectedValues = (value) => {
        if (skillsSelected.includes(value)) {
            setSkillsSelected(skillsSelected.filter((item) => item !== value));
        }
    };

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) => {
                  return option.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <div>
                    <Combobox as="div" value={skillsSelected} onChange={setSkillsSelected} multiple>
                    <div className="relative mt-1">
                        {skillsSelected.length > 0 && (
                            <div className="flex flex-wrap">
                                {skillsSelected.map((value) => (
                                    <div
                                        key={value}
                                        style={{ width: 'fit-content' }}
                                        onClick={() => removeSelectedValues(value)}
                                        className="cursor-pointer flex justify-center items-center m-1 pl-3 pr-2 py-2 border border-indigo-600 rounded-full bg-indigo-600 text-base text-white"
                                    >
                                        <div className="flex flex-initial items-center max-w-full leading-none text-sm font-medium ">
                                            {value}
                                            <XIcon className="ml-1 h-3 w-3 text-gray-100 align-middle" aria-hidden="true" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="relative mt-1">
                            <Combobox.Input
                                className={classNames(
                                    'w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm',
                                    validate ? 'border-red-500 border-2' : ''
                                )}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                        </div>
                        {filteredOptions.length > 0 && (
                            <Combobox.Options className="absolute z-10 bg-white mt-1 max-h-60 w-full overflow-scroll rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredOptions.map((option) => (
                                    <Combobox.Option
                                        key={option}
                                        value={option}
                                        className={({ active }) =>
                                            classNames('relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>{option}</span>
        
                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-indigo-600'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        )}
                    </div>
                </Combobox>
        </div>
    );
}
