/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon, StarIcon } from '@heroicons/react/solid';
import { showEmployeeSummary } from '../../actions';
import { connect } from 'react-redux';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth } from '../../firebase/config';
import React, { useState, useEffect } from 'react';
import { render } from '@testing-library/react';

class EmployeesGrid extends React.Component {

    state = ({employees: []})

    componentDidMount = async () => {
            const q = query(collection(db, 'users', auth.currentUser.uid, 'employees'));
            const querySnapshot = await getDocs(q);
            const employees = []
            querySnapshot.forEach((doc) => {
                employees.push({
                    "name": doc.data().name,
                    "title": doc.data().title,
                    "email": doc.data().email
                })
              });
            this.setState({employees: employees})
    }

    openSummary = () => {
        this.props.showEmployeeSummary(true);
    }

    fetchEmployees = () => {
        const rendered = this.state.employees.map((doc) => {
            return(
                <button
                onClick={this.openSummary}
                key={doc.email}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt="" />
                    </div>
                    <div className="flex-1 min-w-0 person-info">
                        <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                        <p className="text-sm text-gray-500 truncate">{doc.title}</p>
                        </a>
                    </div>
                </button>
            ) 
          });
          return(
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {rendered}
              </div>
          )
    }
    render(){
        return (
            <div>
                            
                {this.fetchEmployees()}
            </div>
        );
    }
}

export default connect(null, {showEmployeeSummary})(EmployeesGrid);
