export default  [
    {
        "email": "gerke.contact@gm",
        "name": "First Person",
        "title": "Developer",
        "wallet-address": "0x24658524b3636152212f140061ed7C5c038BA93F"
    },
    {
        "email": "gerke.contactil.com",
        "name": "Third Person",
        "title": "Project Manager",
        "wallet-address": "0x24658524b3636152212f140061ed7C5c038BA93F"
    },
    {
        "email": "gerke.coail.com",
        "name": "Second Person",
        "title": "Developer",
        "wallet-address": "0x24658524b3636152212f140061ed7C5c038BA93F"
    },
    {
        "email": "gerke.coail.com",
        "name": "Fourth Person",
        "title": "Designer",
        "wallet-address": "0x24658524b3636152212f140061ed7C5c038BA93F"
    },
    {
        "email": "gerke.coail.com",
        "name": "Fifth Person",
        "title": "Designer",
        "wallet-address": "0x24658524b3636152212f140061ed7C5c038BA93F"
    }
]