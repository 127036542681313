import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase/config.js';
import { doc, getDoc } from 'firebase/firestore';

import Loader from './Loader';

export const ProtectedRoute = ({ type }) => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userType, setUserType] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsAuthenticated(true);
                let company;
                try {
                    company = (await getDoc(doc(db, 'users', user.uid))).data();
                } catch (e) {
                    console.log(e);
                }

                if (company) {
                    setUserType('company');
                    setUsername(company.name);
                } else {
                    let talent;
                    try {
                        talent = (await getDoc(doc(db, 'talent', user.uid))).data();
                    } catch (e) {
                        console.log(e);
                    }

                    if (talent) {
                        setUserType('talent');
                        setUsername(talent.full_name);
                    }
                }
            } else {
                // If not in sign in
                // if (!['/signin', '/talent', '/company', '/'].some((r) => window.location.pathname === r)) {
                //     var root = window.location.protocol + '//' + window.location.host;
                //     window.open(root, '_self');
                // }
                setIsAuthenticated(false);
            }
            setLoading(false);
        });
    }, []);

    return <>{loading ? <Loader /> : isAuthenticated && userType === type ? <Outlet /> : <Navigate to="/" replace />}</>;
};
