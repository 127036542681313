import { DocumentTextIcon, MailIcon, UserCircleIcon, UserIcon } from '@heroicons/react/outline';
import { CopyButton } from '../CopyButton';

export const CandidateCard = ({ candidate, preview = false }) => {
    return (
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 h-full flex flex-col h-full">
            <div className="px-4 py-5 sm:px-6">
                <div className="flex  items-center gap-6">
                    {candidate.avatar ? (
                        <img
                            className="inline-block h-12 w-12 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                        />
                    ) : (
                        <div className="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center">
                            <UserIcon className="h-8 w-8 text-gray-500" />
                        </div>
                    )}

                    <div>
                        <h3 className="text-lg text-black-700 font-bold">{preview ? <Placeholder size={120} /> : candidate.full_name}</h3>
                        <p className="mt-1 text-sm text-gray-500">{candidate.role ?? 'Developer'}</p>
                    </div>
                    <div className="ml-auto flex flex-col justify-start items-end">
                        <div className="text-sm text-gray-500 pt-2">{candidate.school}</div>
                        <div className="text-sm text-gray-500 pt-2">
                            {candidate.graduation_month} {candidate.graduation_year}
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
                <div className="flex flex-wrap pt-2 gap-3">
                    {candidate.availability.map((value) => (
                        <div
                            key={value}
                            style={{ width: 'fit-content' }}
                            className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800"
                        >
                            <div className="flex flex-initial items-center max-w-full leading-none text-sm font-medium ">{value}</div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-wrap pt-4 max-w-md gap-2">
                    {candidate.skills.map((value) => (
                        <div
                            key={value}
                            style={{ width: 'fit-content' }}
                            className="flex justify-center items-center mb-1 px-2 py-1 border border-indigo-200 rounded-full bg-indigo-200 text-base text-indigo-800"
                        >
                            <div className="flex flex-initial items-center max-w-full leading-none text-sm font-medium ">{value}</div>
                        </div>
                    ))}
                </div>
                <div className="relative py-4">
                    <div className="absolute inset-0 flex items-center  pt-4" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                </div>
                <div className="flex flex-col pt-4">
                    <span className="text-md text-gray-800 "> Previous Experience</span>
                    {candidate.employments.map((emp, index) => (
                        <div key={index} className="text-sm text-gray-500 flex items-center gap-2">
                            {emp.position} @ {preview ? <Placeholder /> : emp.company}
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-auto">
                <span className="relative z-0 inline-flex shadow-sm w-full flex-grow rounded-b-md ">
                    <button
                        type="button"
                        className="relative inline-flex  items-center justify-center gap-2 px-4 py-6 rounded-bl-md border border-gray-300 bg-indigo-100 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-700"
                        disabled={preview}
                    >
                        <MailIcon className="w-5 h-5" /> Email
                    </button>

                    <button
                        type="button"
                        className={
                            '-ml-px relative inline-flex  items-center justify-center gap-2  text-center px-4 py-6 rounded-br-md border border-gray-300 bg-indigo-100 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-700'
                        }
                        disabled={preview}
                    >
                        <DocumentTextIcon className="w-5 h-5" />
                        Resume
                    </button>
                </span>
            </div>
        </div>
    );
};

const Placeholder = ({ size }) => <div className="bg-gray-500 h-3" style={{ width: `${Math.max(size ? 0.4 * size : 20, Math.random() * (size ?? 50))}%` }} />;
