import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyAao6tX_bRacI32IpeqVPu4Sx4oMXCMzbk',
    authDomain: 'sonder-3d3a1.firebaseapp.com',
    projectId: 'sonder-3d3a1',
    storageBucket: 'sonder-3d3a1.appspot.com',
    messagingSenderId: '606511505974',
    appId: '1:606511505974:web:c21d235ff941ab253d2e97',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage();

export { app, db, auth, storage };
