import PaymentsABI from './abis/PaymentsABI';
import UsdcABI from './abis/UsdcABI';
import { ethers } from 'ethers';
import { browserName, browserVersion } from 'react-device-detect';

let availableProvider = '';
let getUsdcContract = '';
let getPaymentContract = '';

if (window.ethereum) {
    handleEthereum();
} else {
    window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
    });
    setTimeout(handleEthereum, 3000);
}

function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
        availableProvider = browserName === 'Chrome' ? new ethers.providers.Web3Provider(window.ethereum) : ethers.getDefaultProvider();
        getUsdcContract = new ethers.Contract('0xeb8f08a975Ab53E34D8a0330E0D34de942C95926', UsdcABI, availableProvider);
        getPaymentContract = new ethers.Contract('0xE95f5FccB2cb2210B584a77FbC059d39F33d3F14', PaymentsABI, availableProvider);
    } else {
        // alert('Note: If you want to use full functionality of our website please go to google chrome, install metamask extension and set up your account.');
    }
}

export let provider = availableProvider;
export let UsdcContract = getUsdcContract;
export let PaymentsContract = getPaymentContract;
