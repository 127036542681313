import { connect } from 'react-redux';
import { PaperClipIcon } from '@heroicons/react/solid'
import { showEmployeeSummary } from '../../actions';


const EmployeeSummary = (props) => {

    const close = () => {
        props.showEmployeeSummary(false)
    }
    
    return (
        <div>
                {props.summary ? 
                    <div className='summary-dim' onClick={() => props.showEmployeeSummary(false)}>
                        <div className='summary-container'>
                        <div onClick={(e) => e.stopPropagation()} className="rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 payment-summary">
                            <button
                                onClick={close}
                                type="button"
                                className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 close-summary-btn"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Full name</dt>
                            <dd className="mt-1 text-sm text-gray-900">Margot Foster</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Role</dt>
                            <dd className="mt-1 text-sm text-gray-900">Backend Developer</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900">margotfoster@example.com</dd>
                            </div>
                            <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Last transfer</dt>
                            <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
                            </div>
                            <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Last transfer description</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                                qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                                pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                            </dd>
                            </div>
                            <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                    <div className="w-0 flex-1 flex items-center">
                                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span className="ml-2 flex-1 w-0 truncate">resume_back_end_developer.pdf</span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Download
                                    </a>
                                    </div>
                                </li>
                                </ul>
                            </dd>
                            </div>
                        </dl>
                        </div>
                    </div>

                    </div>
            </div>
            :
            ''
            }
        </div>
        
    );
}
const mapStateToProps = (state) => {
    return {summary: state.employeeSummary};
};
export default connect(mapStateToProps, {showEmployeeSummary})(EmployeeSummary);