import { terms } from '../assets/TermsOfUse';
import "../../style/termsModal.css";
import { XIcon } from '@heroicons/react/solid';
import { connect } from 'react-redux';
import {openTermsOfUse} from "../../actions"
import { useEffect } from 'react';

const TermsModal = (props) => {
    return(
        <div>
            {props.openTerms &&
                <div className='terms-dim' onClick={() => props.openTermsOfUse(false)}>
                    <div className='modal' onClick={(e) => e.stopPropagation()}>
                        <div className='close-terms-btn' onClick={() => props.openTermsOfUse(false)}><XIcon className="h-5 w-5" aria-hidden="true"/></div>
                        <div dangerouslySetInnerHTML={{ __html: terms }} />
                    </div>   
                </div>             
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {openTerms: state.isTermsOfUseOpen};
};
export default connect(mapStateToProps, {openTermsOfUse})(TermsModal);