import { useState, useEffect, forwardRef } from 'react';
import Loader from './Loader';
import CompanyHome from './company/CompanyHome';
import TalentHome from './talent/TalentHome';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import 'react-datepicker/dist/react-datepicker.css';

export default function Home(props) {
    const { userType } = props;

    if (userType === 'company') return <CompanyHome />;
    else if (userType === 'talent') return <TalentHome />;
    else return <Loader />;
}
