import Loader from '../Loader';
import EmployeesGrid from './EmployeesGrid';
import employees from "../../testData/employees";
import NewEmployee from "./NewEmployee";
import EmployeeSummary from './EmployeeSummary';

import { PlusIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { newEmployeePageOpen } from '../../actions';
import { connect } from 'react-redux';

function Employees(props) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    if (loading) return <Loader />;

    const renderEmployeesPage = () => {
        if(props.newEmployeeOpen){
            return(
                <div>
                    <button
                        onClick={() => props.newEmployeePageOpen(false)}
                        type="button"
                        className="focus:outline-none inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 back-btn desktop"
                    >
                        Back to Employees
                    </button>
                    <button
                        type="button"
                        className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 back-btn-mobile mobile"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <NewEmployee />
                </div>
            )
        }else{
            return(
                <div className="mx-6 my-5 font-medium">
                    <div className="grid grid-cols-2">
                        <div className="text-2xl font-bold mr-auto">Employees</div>
                        <button
                            onClick={() => props.newEmployeePageOpen(true)}
                            type="button"
                            className="focus:outline-none ml-auto inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                            <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                            Add eployee
                        </button>
                    </div>
                    <div className="mt-4" />
                    <EmployeesGrid data={employees} />
                    <EmployeeSummary />
                </div>
            )
        }
    }
    return (
        <div>
            {renderEmployeesPage()}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {newEmployeeOpen: state.openNewEmployeePage};
};
export default connect(mapStateToProps, { newEmployeePageOpen })(Employees)
