/* This example requires Tailwind CSS v2.0+ */
import { BadgeCheckIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

export default function Banner() {
    const navigate = useNavigate();
    const navCompanyRegister = () => {
        navigate('/company/register');
    };
    return (
        <div className="top-0 inset-x-0 mt-4">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="flex-1 flex items-center gap-1">
                            <span className="flex p-2 rounded-lg bg-indigo-800">
                                <BadgeCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white w-full ">
                                <span className="md:hidden"> Hire from 300+ candidates.</span>
                                <span className="hidden md:inline">Start receiving matches with these candidates and more when you post a role on Sonder.</span>
                            </p>
                        </div>
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <div
                                onClick={() => window.location.href = 'https://buy.stripe.com/cN2g1r71WcuAcTudQQ'}
                                className="cursor-pointer flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                            >
                                Access Now
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
