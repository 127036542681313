import { useNavigate } from 'react-router-dom';
import Priya from '../../assets/images/Priya.webp';

export default function Header() {
    const navigate = useNavigate();

    const navCompanyRegister = () => {
        navigate('/company/register');
    };

    return (
        <div className="relative bg-gray-800 overflow-hidden">
            <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
                <svg
                    className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
                    width={364}
                    height={384}
                    viewBox="0 0 364 384"
                    fill="none"
                >
                    <defs>
                        <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                            <rect x={0} y={0} width={4} height={4} fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
                </svg>
            </div>
            <div className="relative pb-0 sm:pb-24">
                <main className="mt-16 sm:mt-20">
                    <div className="mx-auto max-w-7xl">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                                <div>
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                                        <span className="md:block">The talent pipeline</span> <span className="text-indigo-400 md:block">you never had</span>
                                    </h1>
                                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                        We bridge the gap between you and talented students at top universities in the US and Canada. Within a week, you could
                                        be onboarding your next star intern hire.
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => window.location.href = 'https://buy.stripe.com/cN2g1r71WcuAcTudQQ'}
                                        className="inline-flex items-center mt-6 sm:mt-7 px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 "
                                    >
                                        Get Started
                                    </button>
                                    <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">Used by</p>
                                    <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                        <div className="flex flex-wrap items-start justify-between">
                                            <div className="flex justify-center px-1">
                                                <a href="https://www.aviato.co/">
                                                    <img className="h-9 sm:h-10" src="https://i.ibb.co/KGZxLCP/Mask-group-6.png" alt="Aviato" />
                                                </a>
                                            </div>
                                            <div className="flex justify-center px-1">
                                                <a href="https://www.radix.bio/">
                                                    <img className="h-9 sm:h-10" src="https://i.ibb.co/4djm5rw/Mask-group-11.png" alt="Radix" />
                                                </a>
                                            </div>
                                            <div className="flex justify-center px-1">                                            
                                                <a href="https://www.hostai.app/">
                                                    <img className="h-9 sm:h-10" src="https://i.ibb.co/pXrb6Dg/logo-main-1-1.png" alt="HostAI" />
                                                </a>
                                            </div>
                                            <div className="flex justify-center px-1">
                                                <a href="https://www.minthouse.dev/">
                                                    <img className="h-9 sm:h-10" src="https://i.ibb.co/2sVBPKV/Mask-group-8.png" alt="Minthouse" />
                                                </a>
                                            </div>

                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                                <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                                    <div>
                                        <img src={Priya}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
