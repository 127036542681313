import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import Footer from '../Footer';

import {
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    BriefcaseIcon,
    MenuIcon,
    PencilAltIcon,
    QuestionMarkCircleIcon,
    ReplyIcon,
    SparklesIcon,
    TrashIcon,
    UsersIcon,
    ClockIcon,
    XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';

export default function StudentLanding() {
    const navigate = useNavigate();

    const navTalent = () => {
        navigate('/talent');
    };

    return (
        <div className="bg-white">
            <main>
                {/* Hero section */}
                <div className="relative sm:mt-4">
                    <div className="absolute inset-x-0 bottom-0 bg-gray-100 h-1/2" />
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                            <div className="absolute inset-0">
                                <img
                                    className="object-cover w-full h-full"
                                    src="https://images.pexels.com/photos/3862632/pexels-photo-3862632.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                                    alt="People working on laptops"
                                />
                                <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
                            </div>
                            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                <h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl lg:text-6xl">
                                    <span className="block text-white">Finding an internship?</span>
                                    <span className="block text-indigo-200">We got you.</span>
                                </h1>
                                <p className="max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl">
                                    Sonder is your connection to the network of budding tech startups. Secure your next SWE or UI/UX role at a startup led by
                                    alumni from YC, Forbes 30u30, and more.
                                </p>
                                <div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
                                    <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid ">
                                        <div
                                            onClick={() => window.location.href = 'https://buy.stripe.com/eVabLbdqkbqw06I289'}
                                            className="flex items-center justify-center px-4 py-3 text-base font-medium text-indigo-700 bg-white border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-indigo-50 sm:px-8"
                                        >
                                            Get started
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo Cloud */}
                <div className="bg-gray-100">
                    <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <p className="text-sm font-semibold tracking-wide text-center text-gray-500 uppercase">Trusted by startups and students from</p>
                        <div className="grid grid-cols-2 gap-8 mt-6 md:grid-cols-6 lg:grid-cols-5">
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/HPZ1MJF/Mask-group-15.png" alt="Tuple" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-3 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/jZ679Bd/Mask-group-20.png" alt="StaticKit" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/gZ6S4qR/Mask-group-18.png" alt="Mirage" />
                            </div>{' '}
                            <div className="flex justify-center col-span-1 md:col-span-3 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/80GnHh1/Mask-group-21.png" alt="Workcation" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/1KQTqG3/Mask-group-19.png" alt="StaticKit" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/KGZxLCP/Mask-group-6.png" alt="Mirage" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/2sVBPKV/Mask-group-8.png" alt="StaticKit" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-3 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/4djm5rw/Mask-group-11.png" alt="StaticKit" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-3 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/9b4WVnb/Group-1-7.png" alt="Workcation" />
                            </div>
                            <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1">
                                <img className="h-12" src="https://i.ibb.co/sbWc9F7/Mask-group-10.png" alt="Tuple" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Alternating Feature Sections */}
                <div className="relative pt-16 pb-32 overflow-hidden">
                    <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
                    <div className="relative">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                                <div>
                                    <div>
                                        <span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
                                            <BriefcaseIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                            Finding the <em>right</em> internship has never been easier
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            Tired of networking, applying, keeping track, and interviewing for internships over months? Sonder does <b>all</b>{' '}
                                            of that for you, saving you <b>hundreds</b> of hours.
                                        </p>
                                        <div className="mt-6">
                                            <div
                                                onClick={() => window.location.href = 'https://buy.stripe.com/eVabLbdqkbqw06I289'}
                                                className="inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border hover:from-purple-700 hover:to-indigo-700"
                                            >
                                                Get started
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6 mt-8 border-t border-gray-200">
                                    <blockquote>
                                        <div>
                                            <p className="text-base text-gray-500">
                                                &ldquo;I applied to <b>220</b> positions through LinkedIn/Indeed over several months and got ghosted by most of
                                                them. It took <b>Sonder</b> one try to get me a job. <br></br>
                                                <br></br>
                                                <b>The crazy part is that this job is better than most of the ones I applied to on LinkedIn.</b>&rdquo;
                                            </p>
                                        </div>
                                        <footer className="mt-3">
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-shrink-0">
                                                    <img className="w-6 h-6 rounded-full" src="https://i.ibb.co/K0Fvw73/new-lincoln-png.png" alt="" />
                                                </div>
                                                <div className="text-base font-medium text-gray-700">
                                                    Lincoln Lee, CS @ UBC, SWE Intern @ Radix Labs (YC S19)
                                                </div>
                                            </div>
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0">
                                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src="https://i.ibb.co/1RyyspV/Screen-Shot-2022-07-16-at-5-06-59-PM.png"
                                        alt="Inbox user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-24">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                                <div>
                                    <div>
                                        <span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
                                            <SparklesIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Join top Silicon Valley startups</h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            Join top developers from Stanford, UC Berkeley, University of Waterloo, Ivy Leagues, interning at Meta, Apple, and
                                            YC startups, who use Sonder to find their next internship opportunity at world-changing companies led by Forbes
                                            30u30, YCombinator, and Thiel Fellowship Alumni.
                                        </p>
                                        <div className="mt-6">
                                            <a
                                                onClick={() => window.location.href = 'https://buy.stripe.com/eVabLbdqkbqw06I289'}
                                                className="inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border hover:from-purple-700 hover:to-indigo-700"
                                            >
                                                Get started
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src="https://images.pexels.com/photos/2451646/pexels-photo-2451646.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                                        alt="Customer profile user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Gradient Feature Section */}
                {/* <div className="bg-gradient-to-r from-purple-800 to-indigo-700">
                    <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
                        <h2 className="text-3xl font-extrabold tracking-tight text-white">Perks of being in the Sonder network</h2>
                        <p className="max-w-3xl mt-4 text-lg text-purple-200">We provide you with the direct to-founder network that u don't have</p>
                        <div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name}>
                                    <div>
                                        <span className="flex items-center justify-center w-12 h-12 bg-white rounded-md bg-opacity-10">
                                            <feature.icon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                                        <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

                {/* <div className="pt-16 sm:pt-24" /> */}

                {/* CTA Section */}
                {/* <div className="bg-white">
                    <div className="max-w-4xl px-4 pb-16 mx-auto sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            <span className="block">Ready to get started?</span>
                            <span className="block pb-1 -mb-1 text-transparent bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text">
                                Get access to top internships today.
                            </span>
                        </h2>
                        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                            <div
                                onClick={() => window.location.href = 'https://buy.stripe.com/eVabLbdqkbqw06I289'}
                                className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border hover:from-purple-700 hover:to-indigo-700"
                            >
                                Get started
                            </div>
                        </div>
                    </div>
                </div> */}
            </main>

            <Footer color="bg-black" />
        </div>
    );
}
