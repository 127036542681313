import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';
import { useNavigate, Navigate } from 'react-router-dom';
import { db, auth } from '..//firebase/config.js';

import NavBar from './NavBar';
import Registration from './Registration';
import Home from './Home';
import { Candidates } from './company/Candidates';
import SignIn from './SignIn';
import Payments from './company/Payments';
// import CandidatesPage from './company/CandidatesPage';
import TalentRegistration from './talent/TalentRegistration';
import Landing from './landing/company/Landing';
import Employees from './company/Employees';
import ErrorPage from './ErrorPage';
import RecoverPassword from './RecoverPassword';
import NewJobListing from './company/NewJobListing.js';
import TermsOfUse from './TermsOfUse.js';
import TalentJobPropositions from './talent/TalentJobPropositions.js';
import Invoices from './company/Invoices.js';
import StudentLanding from './landing/students/StudentsLanding.js';
import Loader from './Loader.js';
import { JobPreview } from './JobPreview';
import ViewStudents from './ViewStudents.js';
import { ProtectedRoute } from './ProtectedRoute.js';
import Directory from './landing/company/Directory.js';

function App() {
    const [userType, setUserType] = useState('');
    const [username, setUsername] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoggedIn(true);
                getDoc(doc(db, 'users', user.uid))
                    .then((doc) => {
                        if (doc.data()) {
                            setUserType('company');
                            setUsername(doc.data().name);
                        } else {
                        }
                    })
                    .catch((error) => {
                        console.log('Error getting document:', error);
                    });

                getDoc(doc(db, 'talent', user.uid))
                    .then((doc) => {
                        if (doc.data()) {
                            setUserType('talent');
                            setUsername(doc.data().full_name);
                        } else {
                        }
                    })
                    .catch((error) => {
                        console.log('Error getting document:', error);
                    });
            } else {
                // If not in sign in
                // if (!['/signin', '/talent', '/company', '/'].some((r) => window.location.pathname === r)) {
                //     var root = window.location.protocol + '//' + window.location.host;
                //     window.open(root, '_self');
                // }
                setLoggedIn(false);
                setLoading(false);
            }
        });
    }, []);

    return (
        <Router>
            <NavBar userType={userType} loggedIn={loggedIn} username={username} />

            <Routes>
                <Route path="/" element={<Landing />}></Route>
                <Route path="/students" element={<StudentLanding />}></Route>

                <Route path="/terms" element={<TermsOfUse />}></Route>

                <Route path="/viewstudents" element={<ViewStudents />}></Route>

                <Route path="/directory" element={<Directory />}></Route>

                <Route path="/talent" element={loggedIn ? <Navigate to="/home" replace /> : <TalentRegistration />}></Route>
                <Route path="/signin" element={loggedIn ? <Navigate to="/home" replace /> : <SignIn />}></Route>
                {userType === 'company' && (
                    <Route path="/" element={<ProtectedRoute type="company" />}>
                        <Route path="/candidates/:id" element={<Candidates />}></Route>
                        <Route path="/payments" element={<Payments />}></Route>
                        <Route path="/employees" element={<Employees />}></Route>
                        <Route path="/home" element={<Home userType="company" />}></Route>
                        <Route path="/hire" element={<NewJobListing />} />
                    </Route>
                )}
                {userType === 'talent' && (
                    <Route path="/" element={<ProtectedRoute type="talent" />}>
                        <Route path="/home" element={<Home userType="talent" />}></Route>
                        <Route path="/matches" element={<TalentJobPropositions />}></Route>
                    </Route>
                )}

                <Route path="/recover" element={<RecoverPassword />}></Route>
                {/* <Route path="/candidates/:id" element={<CandidatesPage userType={userType} loggedIn={loggedIn} />}></Route> */}
                {/* <Route path="/candidates" element={loggedIn && userType === 'company' ? <Candidates /> : <Navigate to="/" replace />}></Route> */}
                {/* <Route path="/invoices" element={loggedIn && userType === 'company' ? <Invoices /> : <Navigate to="/" replace />}></Route> */}
                <Route path="/company/register" element={loggedIn ? <Navigate to="/home" replace /> : <Registration />}></Route>
                {!loading && <Route path="*" element={<ErrorPage />}></Route>}
                {/* <Route path="/job/:id" element={<JobPreview />}></Route> */}
            </Routes>
        </Router>
    );
}

export default App;
