/* Page to display for company's active role listings */
import { UserIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Loader from './Loader';

import { db, auth } from '../firebase/config';

export default function ViewStudents() {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const querySnapshot = await getDocs(collection(db, 'talent'));
                const allCandidates = querySnapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                console.log(allCandidates);
                const filteredCandidates = allCandidates.filter(function (el) {
                    return typeof el.graduation_month !== 'undefined';
                });
                setData(filteredCandidates);
                setLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        console.log(data);
    }, [data]);

    if (loading) return <Loader />;
    else
        return (
            <ul role="list" className="m-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {data?.map((candidate) => (
                    <li key={candidate.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="grid grid-rows-1 gap-2">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-gray-900 text-sm font-medium truncate">{candidate.full_name}</h3>
                                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                            {candidate.role}
                                        </span>
                                    </div>
                                    <p className="mt-1 text-gray-500 text-sm truncate">{candidate.salary_range}</p>
                                    <p className="mt-1 text-gray-500 text-sm truncate">{candidate.id}</p>
                                    <p className="mt-1 text-gray-500 text-sm truncate">{candidate.school}</p>
                                    <p className="mt-1 text-gray-500 text-sm truncate">{candidate.calendly}</p>
                                </div>
                                <div>
                                    {candidate.roles?.map((role) => (
                                        <span className="flex-shrink-0 inline-block mr-2 px-2 py-0.5 text-indigo-800 text-xs font-medium bg-indigo-100 rounded-full">
                                            {role}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
}
