import PaymentsGrid from './PaymentsGrid';
import NewPayment from "./NewPayment";
import PaymentSummary from './PaymentSummary';
import "../../style/payments.css"
import payments from "../../testData/payments";

import { PlusIcon } from '@heroicons/react/solid';
import { newPaymentPageOpen } from '../../actions';
import { connect } from 'react-redux';

function Payments(props) {

    const renderPaymentPage = () => {
        if(props.newPaymentOpen){
            return(
                <div>
                    <button
                    onClick={() => props.newPaymentPageOpen(false)}
                        type="button"
                        className=" inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 back-btn desktop"
                    >
                        Back to Payments
                    </button>
                    <button
                        type="button"
                        className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 back-btn-mobile mobile"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <NewPayment />
                </div>
            )
        }else{
            return(
                <div className="mx-6 my-5 font-medium">
                    <div className="grid grid-cols-2">
                        <div className="text-2xl font-bold mr-auto">Payments</div>
                        <button
                            onClick={() => props.newPaymentPageOpen(true)}
                            type="button"
                            className="focus:outline-none ml-auto inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                            <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                            New payment
                        </button>
                    </div>
                    <div className="mt-4" />
                    <PaymentsGrid data={payments} />
                    <PaymentSummary />
                </div>
            )
        }
    }

    return (
        <div>
            {renderPaymentPage()}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {newPaymentOpen: state.openNewPaymentPage};
};
export default connect(mapStateToProps, { newPaymentPageOpen })(Payments)
