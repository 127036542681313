import { CalendarIcon, AcademicCapIcon, ClockIcon, TrashIcon, LocationMarkerIcon, InformationCircleIcon, MinusSmIcon } from '@heroicons/react/solid';
import { collection, query, where, getDocs, orderBy, doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase/config';
import { storeJobListingId } from '../../actions/index';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRefetch } from '../../hooks/useRefetch';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    ArchiveIcon,
    ArrowCircleRightIcon,
    ChevronDownIcon,
    DuplicateIcon,
    HeartIcon,
    PencilAltIcon,
    UserAddIcon,
    PlusSmIcon,
    SparklesIcon,
} from '@heroicons/react/solid';

import Loader from '../Loader';

import '../../style/jobOffers.css';
import { DeleteConfirmationModal } from '../modals/ConfirmationModal';

const JobsGrid = (props) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [activeJob, setActiveJob] = useState(null);

    const [openJob, setOpenJob] = useState(null);

    const [shouldRefetch, refetch] = useRefetch();

    const navigate = useNavigate();

    useEffect(() => {
        let q = '';
        try {
            q = query(collection(db, 'users', auth.currentUser.uid, 'jobs'));
        } catch (e) {
            console.log(e.message);
        }
        const fetchedJobs = [];
        const fetchJobs = async () => {
            // get seen jobs from local storage
            const seenJobs = localStorage.getItem('seenJobs') || '[]';
            const seenJobsIds = JSON.parse(seenJobs);

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                fetchedJobs.push({
                    ...doc.data(),
                    description: doc.data().job_description,
                    title: doc.data().job_title,
                    location: doc.data().job_location,
                    id: doc.id,
                    seen: seenJobsIds.includes(doc.id),
                });
            });
            fetchedJobs.sort((a, b) => {
                return b.candidates?.length ?? 0 - a.candidates?.length ?? 0;
            });

            setJobs(fetchedJobs);
            setLoading(false);
            console.log(fetchedJobs);
        };
        fetchJobs();
    }, [shouldRefetch]);

    const getDataAsString = (date) => {
        if (date) {
            const converted = date.toDate();
            const finalDateFormat = converted.toLocaleDateString();
            return finalDateFormat.toString();
        }
    };

    const editJobOffer = (id) => async () => {
        console.log(id);
        await props.storeJobListingId(id);
        navigate('/hire');
    };

    const openDeleteModal = (id) => async () => {
        setDeleteModalOpen(true);
        setActiveJob(id);
    };

    const deleteJobOffer = async (id) => {
        console.log('deleting', id);
        await deleteDoc(doc(db, 'users', auth.currentUser.uid, 'jobs', id));
        refetch();
    };

    if (loading) return <Loader />;
    else
        return (
            <div>
                <DeleteConfirmationModal action={deleteJobOffer} id={activeJob} open={deleteModalOpen} setOpen={setDeleteModalOpen} />
                <div>
                    <ul role="list">
                        {jobs.length === 0 ? (
                            <div>
                                <div className="text-center text-gray-400 font-medium sm:font-medium text-md sm:text-4xl desktop">
                                    You have no job offers...
                                </div>
                                <div className="mb-10"></div>
                            </div>
                        ) : (
                            jobs.map((job) => (
                                <li key={job.id}>
                                    <div className="block  job-container border-gray-300  shadow-sm border-2 mt-5 rounded-lg job-listing-container">
                                        <div className="px-4 py-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <p className="text-xl font-medium job-offer-title truncate">{job.title}</p>
                                                {!job.seen && job.candidates?.length && (
                                                    <div className="rounded-full bg-blue-50 p-2">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                                                <p className="text-sm text-blue-70 hidden lg:block">We've found candidates for this listing!</p>
                                                                <p className="text-sm text-blue-70 hidden sm:block lg:hidden">New Candidates!</p>
                                                            </div>
                                                            <p className="text-sm mt-0 sm:ml-2 md:ml-3">
                                                                <a
                                                                    href={job.candidates?.length ? '/candidates/' + job.id : ''}
                                                                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600 flex gap-2"
                                                                >
                                                                    <span className="hidden sm:block">See Now</span>
                                                                    <span className="block sm:hidden"> See New Candidates</span>
                                                                    <span aria-hidden="true">&rarr;</span>
                                                                </a>
                                                                <a className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="mt-2 sm:flex sm:justify-between">
                                                <div className="sm:flex">
                                                    <div className="sm:flex sm:mr-4">
                                                        {job.location && (
                                                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                                <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                {job.location}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                        {job.planned_start_date && (
                                                            <div className="flex">
                                                                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                <p>Planned start: {getDataAsString(job.planned_start_date)}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex justify-between gap-3 items-center mt-2 sm:mt-0">
                                                    <ActionMenu id={job.id} deleteItem={openDeleteModal} edit={editJobOffer} />

                                                    <a
                                                        href={job.candidates?.length ? '/candidates/' + job.id : undefined}
                                                        type="button"
                                                        data-tooltip-target={'tooltip-' + job.id}
                                                        className={
                                                            'tooltip inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 gap-2 ' +
                                                            (job.candidates?.length
                                                                ? 'bg-indigo-700 text-indigo-100 hover:bg-indigo-800'
                                                                : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 disabled:opacity-75 disabled:cursor-not-allowed')
                                                        }
                                                        disabled={!job.candidates?.length}
                                                    >
                                                        {!job.candidates?.length ? (
                                                            <>
                                                                Selecting Candidates
                                                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-indigo-500 h-5 w-5" />
                                                                <p className="tooltip--content">We're matching you with the top candidates for this job</p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                See Candidates
                                                                <p className="tooltip--content">We've found you some candidates</p>
                                                            </>
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <div className="w-full border-t border-gray-300" />

                                                <button
                                                    type="button"
                                                    className="inline-flex w-full items-center p-4 text-md  font-medium justify-between  text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                                                    onClick={() => setOpenJob((prev) => (prev === job.id ? null : job.id))}
                                                >
                                                    <span>Details</span>
                                                    {openJob === job.id ? (
                                                        <MinusSmIcon className="-mr-1.5 ml-1 h-8 w-8 text-gray-400" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmIcon className="-mr-1.5 ml-1 h-8 w-8 text-gray-400 " aria-hidden="true" />
                                                    )}
                                                </button>

                                                <Transition
                                                    show={openJob === job.id}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-300"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-300"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <div className="px-4 py-2">
                                                        <div className="text-gray-500">{job.description}</div>
                                                        <div className="mt-2">
                                                            {job.skills && (
                                                                <div className="flex flex-wrap -mx-1 gap-2">
                                                                    {job.skills.map((skill) => (
                                                                        <div
                                                                            key={skill}
                                                                            style={{ width: 'fit-content' }}
                                                                            className="flex justify-center items-center mb-1 px-3 py-2 border border-indigo-200 rounded-full bg-indigo-200 text-base text-indigo-800"
                                                                        >
                                                                            <div className="flex flex-initial items-center max-w-full leading-none text-sm font-medium ">
                                                                                <p className="text-sm leading-5 font-medium">{skill}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        );
};

export default connect(null, { storeJobListingId })(JobsGrid);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ActionMenu = ({ id, deleteItem, edit }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    Options
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {/* <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={'job/' + id}
                                    target="_blank"
                                    className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}
                                >
                                    <UserAddIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Share
                                </a>
                            )}
                        </Menu.Item>
                    </div> */}
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={edit(id)}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm w-full'
                                    )}
                                >
                                    <PencilAltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Edit
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={deleteItem(id)}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm w-full'
                                    )}
                                >
                                    <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
