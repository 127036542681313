/* This example requires Tailwind CSS v2.0+ */

import Elam from '../../assets/images/Elam.webp';
import Victor from '../../assets/images/Victor.webp';
import Miles from '../../assets/images/Miles.webp';

const posts = [
    {
        imageUrl: Elam,
    },
    {
        imageUrl: Victor,
    },
    {
        imageUrl: Miles,
    },
];

export default function StudentFeature() {
    return (
        <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">The ideal intern may not be in your network</h2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        But they are in ours. Don't spend weeks filtering candidates—Sonder's smart matching system will pair you with the perfect interns for
                        your startup in days.
                    </p>
                </div>
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    {posts.map((post) => (
                        <div key={post.imageUrl} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <img src={post.imageUrl} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
