import { useState, useEffect, forwardRef } from 'react';
import '../../style/payments.css';
import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import 'react-datepicker/dist/react-datepicker.css';
import { PlusIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/config';
import { storeJobListingId } from '../../actions/index';
import { connect } from 'react-redux';
import JobsGrid from './JobsGrid';

const CompanyHome = (props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [currentDocId, setCurrentDocId] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(auth.currentUser?.uid);
                getDocs(collection(db, 'users', auth.currentUser?.uid, 'jobs')).then((querySnapshot) => {
                    const colData = querySnapshot.docs[0].data();
                    const docId = querySnapshot.docs[0].id;
                    setCurrentDocId(docId);
                    setData(colData);
                    console.log(colData);
                    setLoading(false);
                });
            }
        });
    }, []);

    const openNewJobOfferPage = async () => {
        console.log('open');
        await props.storeJobListingId('');
        navigate('/hire');
    };

    return (
        <div className="mx-6 my-5 font-medium">
            <div className="grid grid-cols-2">
                <div className="text-2xl font-bold mr-auto">Job Listings</div>
                <button
                    onClick={() => openNewJobOfferPage()}
                    type="button"
                    className="focus:outline-none ml-auto inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                    New job listing
                </button>
            </div>
            <div className="mt-4" />
            <JobsGrid />
        </div>
    );
};
export default connect(null, { storeJobListingId })(CompanyHome);
