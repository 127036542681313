import { useState } from 'react'
import { doc, setDoc } from "firebase/firestore"; 
import { db, auth } from '../../firebase/config';
import { newEmployeePageOpen } from '../../actions';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
import SuccessAlert from '../modals/SuccessAlert';

function NewEmployee(props) {

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [incorrectAddress, setIncorrectAddress] = useState(false);
    const [incorrectName, setIncorrectName] = useState(false);
    const [incorrectEmail, setIncorrectEmail] = useState(false);
    const [incorrectTitle, setIncorrectTitle] = useState(false);
    const { data } = props;

    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    const handleName = (event) => {
        setName(event.target.value);
    }
    const handleTitle = (event) => {
        setTitle(event.target.value);
    }
    const handleEmail = event => { 
        setEmail(event.target.value); 
    }; 
    const handleWalletAddress = event => { 
        setWalletAddress(event.target.value); 
    }; 

    const createNewEmployee = async () => {
        setIncorrectAddress(false);
        setIncorrectName(false);
        setIncorrectTitle(false);
        setIncorrectEmail(false);
        if(name.trim().length == 0){
            setIncorrectName(true);
            return
        }
        if(title.trim().length == 0){
            setIncorrectTitle(true);
            return
        }
        if(!(email.match(mailFormat))){
            setIncorrectEmail(true)
            return
        }
        if(ethers.utils.isAddress(walletAddress)){
            const employeeData = {
                name: name,
                title: title,
                email: email,
                walletAddress: walletAddress
            };
            await setDoc(doc(db, 'users', auth.currentUser.uid, 'employees', String(Date.now())), employeeData).then(() => {
                setShowSuccessAlert(true);
            });
            props.newEmployeePageOpen(false);
        }else{
            setIncorrectAddress(true);
        }
    }

    return (
        <div>
            <SuccessAlert show={showSuccessAlert} setShow={setShowSuccessAlert} />
            <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                <div className="relative max-w-xl mx-auto">
                    <svg
                    className="absolute left-full transform translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                    >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <svg
                        className="absolute right-full bottom-0 transform -translate-x-1/2"
                        width={404}
                        height={404}
                        fill="none"
                        viewBox="0 0 404 404"
                        aria-hidden="true"
                    >
                    <defs>
                        <pattern
                        id="85737c0e-0916-41d7-917f-596dc7edfa27"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                        >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">New Employee</h2>
                    </div>
                    <div className="mt-12">
                        <div action="#" method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                {incorrectName ? <p className='error'>Enter employee name</p> :<p>Name</p>}
                            </label>
                            <div className="mt-1">
                                {incorrectName ?
                                <input
                                onChange={handleName}
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={name}
                                className="error-field py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                                :
                                <input
                                onChange={handleName}
                                type="text"
                                name="first-name"
                                id="first-name"
                                value={name}
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                                }
                            </div>
                            </div>
                            <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                {incorrectTitle ? <p className='error'>Enter employee role in company</p> :<p>Title</p>}
                            </label>
                            <div className="mt-1">
                                {incorrectTitle ?
                                <input
                                onChange={handleTitle}
                                type="text"
                                name="last-name"
                                id="last-name"
                                value={title}
                                className="error-field py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                                :
                                <input
                                onChange={handleTitle}
                                type="text"
                                name="last-name"
                                id="last-name"
                                value={title}
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                                }
                            </div>
                            </div>
                            <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {incorrectEmail ? <p className='error'>Enter employee email</p> :<p>Email</p>}
                            </label>
                            <div className="mt-1">
                                {incorrectEmail ?
                                    <input
                                    onChange={handleEmail}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    className="error-field py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                    :
                                    <input
                                    onChange={handleEmail}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                }
                            </div>
                            </div>
                            <div className="sm:col-span-2">
                            <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                                {incorrectAddress ? <p className='error'>Enter correct ETH wallet address</p> :<p>Wallet Address</p>}
                            </label>
                            <div className="mt-1">
                                {incorrectAddress ?
                                <input
                                onChange={handleWalletAddress}
                                type="text"
                                name="wallet"
                                id="wallet"
                                value={walletAddress}
                                className="error-field py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                                :
                                <input
                                onChange={handleWalletAddress}
                                type="text"
                                name="wallet"
                                id="wallet"
                                value={walletAddress}
                                className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                />
                            }
                            </div>
                            </div>
                            <div className="sm:col-span-2">
                            <button
                                onClick={createNewEmployee}
                                type="button"
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            >
                                Add
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default connect(null, { newEmployeePageOpen })(NewEmployee)