import { useEffect, useState } from 'react';
import { passwordStrength } from 'check-password-strength';

export default function Password(props) {
    const { password, setPassword } = props;
    const [strengthMeterWidthColor, setStrengthMeterWidthColor] = useState('w-auto');

    const updatePassword = (value) => {
        switch (passwordStrength(value).value) {
            case 'Too weak':
                setStrengthMeterWidthColor('w-1/4 bg-red-500');
                break;
            case 'Weak':
                setStrengthMeterWidthColor('w-2/4 bg-amber-500');
                setPassword(value);
                break;
            case 'Medium':
                setStrengthMeterWidthColor('w-3/4 bg-green-500');
                setPassword(value);
                break;
            case 'Strong':
                setStrengthMeterWidthColor('w-4/4 bg-green-500');
                setPassword(value);
                break;
        }
    };

    useEffect(() => {
        if (document.querySelector('.js-password-toggle')) {
            const passwordToggle = document.querySelector('.js-password-toggle');

            passwordToggle.addEventListener('change', function () {
                console.log('changing');
                const password = document.querySelector('.js-password'),
                    passwordLabel = document.querySelector('.js-password-label');

                if (password.type === 'password') {
                    password.type = 'text';
                    passwordLabel.innerHTML = 'hide';
                } else {
                    password.type = 'password';
                    passwordLabel.innerHTML = 'show';
                }

                password.focus();
            });
        }
    }, [document.querySelector('.js-password-toggle')]);

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <div className="mt-1 relative">
                <input
                    type="password"
                    name="password"
                    maxLength={28}
                    onChange={(e) => updatePassword(e.target.value)}
                    id="password"
                    className="js-password shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <div className="absolute inset-y-0 right-0 flex items-center px-2">
                    <input className="hidden js-password-toggle" id="toggle" type="checkbox" />
                    <label
                        className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                        for="toggle"
                    >
                        show
                    </label>
                </div>
            </div>
            <div className={'h-1 mt-1.5 rounded-sm ' + strengthMeterWidthColor}> </div>
        </div>
    );
}
