import { useState, useEffect, forwardRef } from 'react';
import TextField from '../form/TextField';
import DatePicker from 'react-datepicker';
import TextArea from '../form/TextArea';
import Select from '../form/Select';
import MultiselectComboBox from '../form/MultiSelectComboBox';
import Loader from '../Loader';
import SuccessAlert from '../modals/SuccessAlert';

import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { db, auth } from '../../firebase/config';

function NewJobListing(props) {
    const [startDate, setStartDate] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [jobDescription, setJobDescription] = useState('');

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const navigate = useNavigate();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && props.listingId !== '') {
                getDoc(doc(db, 'users', auth.currentUser?.uid, 'jobs', props.listingId)).then((querySnapshot) => {
                    const colData = querySnapshot.data();
                    setData(colData);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });
    }, []);

    const openNewJob = async () => {
        const jobData = {
            job_title: jobTitle,
            job_location: jobLocation,
            job_description: jobDescription,
            planned_start_date: startDate,
        };
        if (props.listingId !== '') {
            updateDoc(doc(db, 'users', auth.currentUser.uid, 'jobs', props.listingId), jobData).then(() => {
                navigate('/home');
            });
        } else {
            await addDoc(collection(db, 'users', auth.currentUser.uid, 'jobs'), jobData).then(() => {
                navigate('/home');
            });
        }
    };

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <div>
            <label className="block text-sm font-medium text-gray-700">Planned start date</label>
            <input
                type={'text'}
                onClick={onClick}
                ref={ref}
                name={'startDate'}
                id={'startDate'}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={value}
            />
        </div>
    ));

    useEffect(() => {
        if (data.job_title) {
            setJobTitle(data.job_title);
        }
        if (data.job_location) {
            setJobLocation(data.job_location);
        }
        if (data.job_description) {
            setJobDescription(data.job_description);
        }
        if (data.planned_start_date) {
            setStartDate(data.planned_start_date.toDate());
        }
    }, [data]);

    const CompanyHomeContent = (
        <div>
            {showSuccessAlert && <SuccessAlert show={showSuccessAlert} setShow={setShowSuccessAlert} />}
            <div className="mx-6 mt-6 sm:mt-10 mb-10">
                <div className="flex justify-between">
                    <div className="text-2xl font-bold">Enter Role Details</div>
                </div>

                <div className="mt-5" />
                <TextField
                    required
                    onChange={(e) => setJobTitle(e.target.value)}
                    text={'Job title'}
                    value={jobTitle}
                    altText={'i.e. Fullstack Engineer, iOS/Swift Engineer'}
                    type={'text'}
                    id={'job-title'}
                    name={'job-title'}
                />
                <div className="mt-3" />
                <TextField
                    required
                    onChange={(e) => setJobLocation(e.target.value)}
                    text={'Job location'}
                    value={jobLocation}
                    type={'text'}
                    id={'job-location'}
                    name={'job-location'}
                    altText={'Please indicate if remote is allowed'}
                />
                <div className="mt-3" />
                <TextArea value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} text={'Job description'} altText={'Optional'} />
                <div className="mt-3" />
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<CustomDateInput />} />

                <div className="mt-10" />
                <div className="text-center">
                    <div className="grid grid-cols-1 gap-4">
                        {loading ? (
                            <button
                                type="submit"
                                onClick={openNewJob}
                                className="inline-flex mr-auto items-center px-10 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none "
                            >
                                <div className="button-loader">Loading...</div>
                            </button>
                        ) : (
                            <button
                                type="submit"
                                onClick={openNewJob}
                                className="inline-flex mx-auto items-center px-10 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none "
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    if (loading) return <Loader />;
    else
        return (
            <div className="sm:grid sm:grid-cols-7">
                <div className="col-span-2"> </div>
                <div className="col-span-3">{CompanyHomeContent}</div>
                <div className="col-span-2"> </div>
            </div>
        );
}
const mapStateToProps = (state) => {
    return { listingId: state.jobListingId };
};
export default connect(mapStateToProps, {})(NewJobListing);
