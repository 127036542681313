import Header from './Header';
import Steps from './Steps';
import StudentFeature from './StudentFeature';
import Pricing from './Pricing';
import FAQ from './FAQ';
import Footer from '../Footer';

export default function Landing() {
    return (
        <div>
            <Header />
            <StudentFeature />
            <Steps />
            <Pricing />
            <FAQ />
            <Footer color="bg-gray-800" />
        </div>
    );

    //what do we give you? Nothing but the best -- feature some top students
    //the workflow: create a role with requirements; start seeing AI generated candidates; start scheduling interviews; hire!
    //Skills you can hire for: full stack, ML, mobile, systems engineering, UI/UX -- maybe not necessary
    //Pricing
    //FAQ
    //Footer/start now
}
