import { useState, useEffect, useCallback } from 'react';
import Loader from '../Loader';
import TextField from '../form/TextField';
import Select from '../form/Select';
import MultiselectComboBox from '../form/MultiSelectComboBox';
import { ReactComponent as CV } from '../assets/CV.svg';

import { getFirestore, collection, setDoc, doc, getDoc, updateDoc, getDocs, arrayRemove } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getMetadata } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { skillOptions } from '../variables/SkillOptions';
import { roleOptions } from '../variables/RoleOptions';
import { salaryOptions } from '../variables/SalaryOptions';
import { availabilityOptions } from '../variables/AvailabilityOptions';
import { graduationYearOptions, graduationMonthOptions } from "../variables/Graduation";

import SuccessAlert from '../modals/SuccessAlert';

import { db, auth, storage } from '../../firebase/config';

const commitmentOptions = ['20 hrs/wk', '40 hrs/wk', '40+ hrs/wk'];
const paymentOptions = ['Bank/wire transfer', 'Crypto (Stablecoin)'];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function TalentHome() {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [data, setData] = useState('');
    const [onboarded, setOnboarded] = useState(false);
    const [name, setName] = useState('');
    const [nameValidate, setNameValidate] = useState(false);
    const [skillsSelected, setSkillsSelected] = useState([]);
    const [skillsValidate, setSkillsValidate] = useState(false);
    const [availability, setAvailability] = useState([]);
    const [availabilityValidate, setAvailabilityValidate] = useState('');
    const [rolesSelected, setRolesSelected] = useState([]);
    const [rolesValidate, setRolesValidate] = useState(false);
    const [graduationYear, setGraduationYear] = useState('');
    const [graduationMonth, setGraduationMonth] = useState('');
    const [graduationMonthValidate, setGraduationMonthValidate] = useState(false);
    const [graduationYearValidate, setGraduationYearValidate] = useState(false);
    const [school, setSchool] = useState('');
    const [schoolValidate, setSchoolValidate] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const [salarySelected, setSalarySelected] = useState('');
    const [salaryValidate, setSalaryValidate] = useState(false);

    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [resumeValidate, setResumeValidate] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const drop = useCallback(node => {
        if(node !== null){
            node.addEventListener('dragover', handleDragOver);
            node.addEventListener('drop', handleDrop);  
        }
    })
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
      
    const handleDrop = async (e) => {
        e.preventDefault();
        const {files} = await e.dataTransfer;
        const droppedFile = files[0];
        setFile(droppedFile);
        setFileName(droppedFile.name)
        e.stopPropagation();
    };

    const validate = () => {
        setNameValidate(false);
        setSkillsValidate(false);
        setRolesValidate(false);
        setSalaryValidate(false);
        setAvailabilityValidate(false);
        setGraduationYearValidate(false);
        setGraduationMonthValidate(false);
        setSchoolValidate(false);
        if (!name) {
            setNameValidate(true);
            return;
        }
        if (!fileName) {
            setResumeValidate(true);
            return;
        }
        if (rolesSelected.length === 0) {
            setRolesValidate(true);
            return;
        }
        if (!school) {
            setSchoolValidate(true);
            return;
        }
        if (!graduationMonth) {
            setGraduationMonthValidate(true);
            return;
        }
        if (!graduationYear) {
            setGraduationYearValidate(true);
            return;
        }
        if (!(availability.length > 0)) {
            setAvailabilityValidate(true);
            return;
        }
        // if (!file) {
        //     setFileValidate(true);
        //     return;
        // }
        updateData();
    };

    const updateData = () => {
        setUpdating(true);
        const storageRef = ref(storage, `talent/resumes/${auth.currentUser.uid}`);
        const userData = {
            full_name: name,
            skills: skillsSelected,
            roles: rolesSelected,
            salary_range: salarySelected,
            onboarded: false,
            resume_name: fileName,
            graduation_month: graduationMonth,
            graduation_year: graduationYear,
            availability: availability,
            school: school,
        };
        setDoc(doc(db, 'talent', auth.currentUser.uid), userData).then(() => {
            if (fileName) {
                const metadata = {
                    nameOfFile: fileName,
                    contentType: 'application/pdf',
                };
                uploadBytes(storageRef, file, metadata).then((snapshot) => {
                    setUpdating(false);
                });
            }
            setUpdating(false);
            setShowSuccessAlert(true);
        });
    };

    useEffect(() => {
        setLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, 'talent', user.uid)).then((docSnap) => {
                    setData(docSnap.data());
                    if (docSnap.data().onboarded) {
                        setOnboarded(docSnap.data().onboarded);
                    }
                    if (docSnap.data().resume_name) {
                        setFileName(docSnap.data().resume_name);
                    }
                    if (docSnap.data().full_name) {
                        setName(docSnap.data().full_name);
                    }
                    if (docSnap.data().roles) {
                        setRolesSelected(docSnap.data().roles);
                    }
                    if (docSnap.data().graduation_month) {
                        setGraduationMonth(docSnap.data().graduation_month);
                    }
                    if(docSnap.data().graduation_year){
                        setGraduationYear(docSnap.data().graduation_year)
                    }
                    if (docSnap.data().availability) {
                        setAvailability(docSnap.data().availability);
                    }
                    if (docSnap.data().school) {
                        setSchool(docSnap.data().school);
                    }
                    if (docSnap.data().skills) {
                        setSkillsSelected(docSnap.data().skills);
                    }
                    if (docSnap.data().salary_range) {
                        setSalarySelected(docSnap.data().salary_range);
                    }
                    setLoading(false);
                });
            }
        });
    }, []);

    useEffect(() => {
        if (data.onboarded) {
            if (data.full_name) {
                setName(data.full_name);
            }
            if (data.resume_name) {
                setFileName(data.resume_name);
            }
            if (data.roles) {
                setRolesSelected(data.roles);
            }
            if (data.school) {
                setSchool(data.school);
            }
            if (data.graduation_month) {
                setGraduationMonth(data.graduation_month);
            }
            if (data.graduation_year) {
                setGraduationYear(data.graduation_year);
            }
            if (data.availability) {
                setAvailability(data.availability);
            }
            if (data.skills) {
                setSkillsSelected(data.skills);
            }
            if (data.salary_range) {
                setSalarySelected(data.salary_range);
            }
        }
    }, [data]);

    const TalentHomeContent = (
        <div className="mx-6 mt-6 mb-10">
            {onboarded ? (
                <div>
                    <div className="text-2xl font-bold">Update Profile</div>
                    <div className="mt-2" />
                </div>
            ) : (
                <div>
                    <div className="text-2xl font-bold">Your Profile</div>
                    <div className="mt-2" />
                    <div className="text-sm">The more fields you fill out the greater the chance of finding a perfect internship for you!</div>
                </div>
            )}
            <div className="mt-5" />
            <TextField
                required
                onChange={(e) => setName(e.target.value)}
                text={'Full Name'}
                type={'text'}
                id={'name'}
                name={'name'}
                value={name}
                validate={nameValidate}
            />
            <div className="mt-5" />
            <label className="block text-sm font-medium text-gray-700">Resume</label>
            <div ref={drop} className={classNames(resumeValidate ? 'mt-1 max-w-2xl flex justify-center px-6 pt-5 pb-6 border-2 border-red-300 border-dashed rounded-md' : 'mt-1 max-w-2xl flex justify-center px-6 pt-5 pb-6 border-2 border-grey-300 border-dashed rounded-md')}>
                <div className="space-y-1 text-center">
                    <CV />
                    <div className="text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                            {!fileName && <span>Upload a file</span>}
                            {fileName && <span>{fileName}</span>}
                            <input onChange={handleChange} multiple={false} id="file-upload" name="file-upload" type="file" accept=".pdf" className="sr-only" />
                        </label>
                    </div>
                    <p className="text-xs text-gray-500">PDF up to 10MB</p>
                </div>
            </div>
            <div className="mt-5" />
            <label className="block text-sm font-medium text-gray-700">Roles</label>
            <MultiselectComboBox validate={rolesValidate} skillsSelected={rolesSelected} setSkillsSelected={setRolesSelected} options={roleOptions} />
            <div className="mt-5" />
            <div>
                <label className="block text-sm font-medium text-gray-700">School</label>
                <div className="mt-1">
                    <input
                        type="text"
                        onChange={(e) => setSchool(e.target.value)}
                        name="graduation"
                        id="graduation"
                        value={school}
                        className={
                            classNames(schoolValidate ? 
                            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-red-300 rounded-md' 
                            :
                            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md')}
                        /> 
                </div>
            </div>
            <div className="mt-5"/>
            <div className='flex'>
            <div className='w-6/12 mr-8'>
            <Select validate={graduationYearValidate} options={graduationYearOptions} selected={graduationYear} setSelected={setGraduationYear} text={'Graduation Year'} />
            </div>
            <div className='w-6/12'>
            <Select validate={graduationMonthValidate} options={graduationMonthOptions} selected={graduationMonth} setSelected={setGraduationMonth} text={'Graduation Month'} />
            </div>
            </div>
            <div className="mt-5" />
            <label className="block text-sm font-medium text-gray-700">Availability</label>
            <MultiselectComboBox
                validate={availabilityValidate}
                options={availabilityOptions}
                skillsSelected={availability}
                setSkillsSelected={setAvailability}
            />
            <div className="mt-5" />
            <label className="block text-sm font-medium text-gray-700">Skills</label>
            <label className="block text-xs text-gray-500">Optional</label>
            <MultiselectComboBox validate={skillsValidate} skillsSelected={skillsSelected} setSkillsSelected={setSkillsSelected} options={skillOptions} />

            <div className="mt-5" />
            <label className="block text-sm font-medium text-gray-700">Hourly Salary</label>
            <label className="block text-xs text-gray-500">Optional</label>
            <Select validate={salaryValidate} options={salaryOptions} selected={salarySelected} setSelected={setSalarySelected} />
            <div className="mt-10" />
            <div className="text-center">
                {updating ? (
                    <button
                        type="button"
                        onClick={validate}
                        className="inline-flex items-center px-10 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                    >
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-500 h-6 w-6" />
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={validate}
                        className="inline-flex items-center px-10 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none "
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    );

    if (loading) return <Loader />;
    else
        return (
            <div>
                <div className="sm:grid sm:grid-cols-7 ">
                    <SuccessAlert show={showSuccessAlert} setShow={setShowSuccessAlert} text={'Your information was updated.'} />
                    <div className="col-span-2"> </div>
                    <div className="col-span-3">{TalentHomeContent} </div>
                    <div className="col-span-2"> </div>
                </div>
            </div>
        );
}
