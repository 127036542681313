import Banner from './Banner';
import DirectoryGrid from './DirectoryGrid';

export default function Directory() {
    return (
        <div className="mx-8 my-4">
            <Banner />
            <div className="mt-6" />
            <DirectoryGrid />
        </div>
    );
}
