import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { PaymentsContract, UsdcContract, provider } from '../../ethereum/Contracts';
import { ethers } from 'ethers';
import { collection, query, where, getDocs, doc, setDoc, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { db, auth, storage } from '../../firebase/config';
import { newPaymentPageOpen } from '../../actions';
import { connect } from 'react-redux';

import SuccessAlert from '../modals/SuccessAlert';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function NewPayment(props) {
    const [chosenEmployee, setChosenEmployee] = useState({});
    const [chosenEmployeeName, setChosenEmployeeName] = useState('Employees');
    const [chosenEmployeeAddress, setChosenEmployeeAddress] = useState('');
    const [incorrectEmployee, setIncorrectEmployee] = useState(false);
    const [incorrectValue, setIncorrectValue] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [cryptocurrency, setCryptocurrency] = useState('');
    const [contractApproved, setContractApproved] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [invoiceAdded, setInvoiceAdded] = useState(false);
    const fee = 0.02;
    const drop = useRef(null);

    useEffect(() => {
        const q = query(collection(db, 'users', auth.currentUser.uid, 'employees'));
        const employees = [];
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                employees.push({
                    name: doc.data().name,
                    title: doc.data().title,
                    email: doc.data().email,
                    walletAddress: doc.data().walletAddress,
                });
            });
            setEmployees(employees);
        };
        fetchEmployees();
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
    }, []);

    const approve = async () => {
        setIncorrectEmployee(false);
        setIncorrectValue(false);
        setLoader(true);
        if (chosenEmployeeName == 'Employees') {
            setIncorrectEmployee(true);
            setLoader(false);
            return;
        }
        if (value <= 0) {
            setIncorrectValue(true);
            setLoader(false);
            return;
        }
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const usdcWithSigner = UsdcContract.connect(signer);
        const approveValue = value * 1020000;
        try {
            const approve = await usdcWithSigner.approve('0xE95f5FccB2cb2210B584a77FbC059d39F33d3F14', approveValue);
            await approve.wait();
            setContractApproved(true);
        } catch (e) {
            alert('something went wrong');
            setLoader(false);
        }
        setLoader(false);
    };

    const pay = async () => {
        setLoader(true);
        setIncorrectEmployee(false);
        setIncorrectValue(false);
        if (chosenEmployeeName == 'Employees') {
            setIncorrectEmployee(true);
            setLoader(false);
            return;
        }
        if (value <= 0) {
            setIncorrectValue(true);
            setLoader(false);
            return;
        }
        if (cryptocurrency === 'USDC') {
            const convertedUSDC = value * 1020000;
            await provider.send('eth_requestAccounts', []);
            const signer = provider.getSigner();
            const paymentWithSigner = PaymentsContract.connect(signer);
            try {
                const tx = await paymentWithSigner.transferERC20('0xeb8f08a975Ab53E34D8a0330E0D34de942C95926', chosenEmployeeAddress, convertedUSDC, {
                    gasLimit: 1000000,
                });
                await tx.wait();
                const paymentData = {
                    name: chosenEmployee.name,
                    title: chosenEmployee.title,
                    email: chosenEmployee.email,
                    walletAddress: chosenEmployee.walletAddress,
                    amount: value,
                    cryptocurrency: cryptocurrency,
                    description: description,
                    timestamp: Date.now(),
                    invoiceAdded: invoiceAdded,
                };
                const docRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'payments'), paymentData);
                const storageRef = ref(storage, `user/invoices/${docRef.id}`);
                await uploadBytes(storageRef, selectedFile);
                setShowSuccessAlert(true);
                setContractApproved(false);
                props.newPaymentPageOpen(false);
            } catch (e) {
                setLoader(false);
                console.log(e.message);
                alert('Something went wrong...');
            }
        } else if (cryptocurrency === 'ETH') {
            const ethValue = value * 1.02;
            const finalETH = ethValue.toString();
            await provider.send('eth_requestAccounts', []);
            const signer = provider.getSigner();
            const paymentWithSigner = PaymentsContract.connect(signer);
            try {
                const tx = await paymentWithSigner.payInETH(chosenEmployeeAddress, {
                    gasLimit: 1000000,
                    value: ethers.utils.parseEther(finalETH),
                });
                await tx.wait();
                const paymentData = {
                    name: chosenEmployee.name,
                    title: chosenEmployee.title,
                    email: chosenEmployee.email,
                    walletAddress: chosenEmployee.walletAddress,
                    amount: value,
                    cryptocurrency: cryptocurrency,
                    description: description,
                    timestamp: Date.now(),
                    invoiceAdded: invoiceAdded,
                };
                const docRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'payments'), paymentData);
                const storageRef = ref(storage, `user/invoices/${docRef.id}`);
                await uploadBytes(storageRef, selectedFile);
                setShowSuccessAlert(true);
                props.newPaymentPageOpen(false);
            } catch (e) {
                setLoader(false);
                console.log(e.message);
                alert('Something went wrong...');
            }
        }
        setLoader(false);
    };

    const handleDescription = (event) => {
        setDescription(event.target.value);
    };
    const handleValue = (event) => {
        setValue(event.target.value);
    };
    const handleFile = (event) => {
        event.preventDefault();
        setInvoiceAdded(true);
        setSelectedFile(event.target.files[0]);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const { files } = await e.dataTransfer;
        setSelectedFile(files[0]);
        setInvoiceAdded(true);
        e.stopPropagation();
    };

    const chooseEmployee = (employee) => {
        setChosenEmployeeName(employee.name);
        setChosenEmployeeAddress(employee.walletAddress);
        setChosenEmployee(employee);
    };
    const renderEmployees = () => {
        const rendered = employees.map((employee) => {
            return (
                <Menu.Item key={employee.email} className="employee-payment-dropdown">
                    {({ active }) => (
                        <button
                            onClick={() => chooseEmployee(employee)}
                            className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                        >
                            {employee.name}
                        </button>
                    )}
                </Menu.Item>
            );
        });
        return <div>{rendered}</div>;
    };

    const renderTotal = () => {
        if (cryptocurrency == 'ETH') {
            return (
                <div className="new-payment-fees">
                    Total: {(value * (1 + fee)).toFixed(3)} {cryptocurrency}
                    <p className="new-payment-fee-note">({fee * 100}% fee included)</p>
                </div>
            );
        } else if (cryptocurrency == 'USDC') {
            return (
                <div className="new-payment-fees">
                    Total: {(value * (1 + fee)).toFixed(2)} {cryptocurrency}
                    <p className="new-payment-fee-note">({fee * 100}% fee included)</p>
                </div>
            );
        }
    };

    return (
        <div>
            <SuccessAlert show={showSuccessAlert} setShow={setShowSuccessAlert} />
            <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                <div className="relative max-w-xl mx-auto">
                    <svg className="absolute left-full transform translate-x-1/2" width={404} height={404} fill="none" viewBox="0 0 404 404" aria-hidden="true">
                        <defs>
                            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <svg
                        className="absolute right-full bottom-0 transform -translate-x-1/2"
                        width={404}
                        height={404}
                        fill="none"
                        viewBox="0 0 404 404"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                    </svg>
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">New Payment</h2>
                    </div>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <div className="mt-1">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    {incorrectEmployee ? <p className="error">Choose employee</p> : <p>Choose Employee</p>}
                                </label>
                                <Menu as="div" className="block w-full relative inline-block text-left py-1 employees-dropdown">
                                    <div>
                                        <Menu.Button className="text-left inline-flex w-full rounded-md border border-gray-300 shadow-sm py-3 px-4 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-gray-100 focus:ring-indigo-500">
                                            {chosenEmployeeName}
                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none employee-payment-container">
                                            {renderEmployees()}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 choose-currency-label">
                                    {incorrectValue ? <p className="error">Incorrect Value</p> : <p>Value({cryptocurrency ? cryptocurrency : 'Choose'})</p>}
                                    <span className="relative px-2 z-0 inline-flex shadow-sm rounded-md">
                                        <button
                                            onClick={() => setCryptocurrency('ETH')}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-0 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            ETH
                                        </button>
                                        <button
                                            onClick={() => setCryptocurrency('USDC')}
                                            type="button"
                                            className="-ml-px relative inline-flex items-center px-4 py-0 rounded-r-md  border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            USDC
                                        </button>
                                    </span>
                                </label>

                                <div className="mt-1">
                                    {incorrectValue ? (
                                        <input
                                            min="1"
                                            type="number"
                                            name="first-name"
                                            id="first-name"
                                            onChange={handleValue}
                                            value={value}
                                            autoComplete="given-name"
                                            className="error-field py-2 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md value-input"
                                        />
                                    ) : (
                                        <input
                                            min="1"
                                            type="number"
                                            name="first-name"
                                            id="first-name"
                                            onChange={handleValue}
                                            value={value}
                                            autoComplete="given-name"
                                            className="py-2 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md value-input"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                    Short Description
                                </label>
                                <div className="mt-1">
                                    <textarea
                                        id="message"
                                        name="message"
                                        onChange={handleDescription}
                                        value={description}
                                        rows={2}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                                    Attach Invoice
                                </label>
                                <div className="mt-1">
                                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" ref={drop}>
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            {invoiceAdded ? (
                                                <div>
                                                    {selectedFile.name}
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFile} />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFile} />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {value && cryptocurrency ? renderTotal() : ''}
                            <div className="sm:col-span-2">
                                {cryptocurrency === 'ETH' ? (
                                    loader ? (
                                        <button
                                            onClick={pay}
                                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 focus:outline-none focus:ring-indigo-500"
                                        >
                                            <div className="button-loader">Loading...</div>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={pay}
                                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500"
                                        >
                                            Send
                                        </button>
                                    )
                                ) : loader ? (
                                    <button
                                        onClick={approve}
                                        className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 focus:outline-none focus:ring-indigo-500"
                                    >
                                        <div className="button-loader">Loading...</div>
                                    </button>
                                ) : contractApproved ? (
                                    <button
                                        onClick={pay}
                                        className="focus:outline-none w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500"
                                    >
                                        Send
                                    </button>
                                ) : (
                                    <button
                                        onClick={approve}
                                        className="focus:outline-none w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500"
                                    >
                                        Approve
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect(null, { newPaymentPageOpen })(NewPayment);
